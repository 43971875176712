import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import '@adyen/adyen-web/dist/adyen.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'toastr/build/toastr.min.js'
import 'toastr/build/toastr.css'
import { AssociateContextProvider } from './context/AssociateContext';

export default function App() {
  return (
    <>
      <AssociateContextProvider>
        <BrowserRouter>
          <main className="app">
            <Routes className="Login" />
          </main>
        </BrowserRouter>
      </AssociateContextProvider>
    </>
  );
}


