import React from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { gravarLogGenerico } from "../service/gravarLog";
import { showMessageError } from "../others/toastr";

export default function BoletoButton ({dadosLogPagamento,origemBoleto}) {
    const history = useHistory()

    const HandleClick = async () =>{     
        try {
            await gravarLogGenerico('/log-area-cliente/log-pagamento', dadosLogPagamento)
            history.push(`/imprimir/boleto/${origemBoleto}`)
        } catch (error) {
            showMessageError('Algo deu errado, tente novamente mais tarde!')
        }
    }

    return <Button onClick={HandleClick} label="Boleto" className="p-button-raised p-button-info" />
}