import { apiPix } from './api'

export async function gravarLogGenerico(routeLog, bodyDadosLog) {
    try {
        const { status } = await apiPix.post(routeLog, bodyDadosLog)
        return status
    } catch (error) {
        console.error(error)
    }

}

