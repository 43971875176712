import * as yup from "yup";
import { validaTelefone } from "../../../utils/validateTelefone";

yup.addMethod(yup.string, "validaTelefone", function (args) {
  const { message } = args;

  return this.test("validaTelefone", message, function (value) {
    const { path, createError } = this;

    const telefoneFormatado = value && value.replace(/ |-|\(|\)/gi, "");
    const telefoneValido =
      telefoneFormatado && validaTelefone(telefoneFormatado);

    return telefoneValido
      ? value
      : createError({
          path,
          message,
        });
  });
});

export const validationSchema = yup.object().shape({
  Nome: yup.string().nullable(),
  AssEmail: yup
    .string()
    .email("Email está incorreto")
    .max(50, "Tamanho máximo para este campo é de 50 caracteres")
    .required("Email é obrigatório"),
  RG: yup.string().nullable(),
  CPF: yup.string().nullable(),
  CEPResid: yup.string().nullable().required("Cep é obrigatório"),
  Residencia: yup.string().nullable().required("Resdidência é obrigatório"),
  numeroResidencia: yup.string().nullable().required("Número é obrigatório"),
  Bairro: yup.string().nullable().required("Bairro é obrigatório"),
  Telefone: yup
    .string()
    .nullable()
    .required("Telefone é obrigatório")
    .validaTelefone({ message: "Telefone inválido" }),
  Celular: yup
    .string()
    .nullable()
    .required("Celular é obrigatório")
    .validaTelefone({ message: "Celular inválido" }),
});

export const retirarNulosCargaFormulario = (dados) => {
  return {
    Nome: dados.Nome ?? " ",
    AssEmail: dados.AssEmail ?? " ",
    Residencia: dados.Residencia ?? " ",
    Bairro: dados.Bairro ?? " ",
    CEPResid: dados.CEPResid ?? " ",
    CPF: dados.CPF ?? " ",
    RG: dados.RG ?? " ",
    Telefone: dados.Telefone ?? " ",
    Celular: dados.Celular ?? " ",
    numeroResidencia: dados.numeroResidencia ?? " ",
  };
};
