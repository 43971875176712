import Axios from "axios";
import { getValue } from "../../utils/hash";

const urlAdyen = getValue(process.env.REACT_APP_5);
const token = getValue(process.env.REACT_APP_17);


export const apiAdyen = Axios.create({
    baseURL: urlAdyen,
    headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
})