import React from 'react'
import Main from './component/template/Main'

const notFound = () => 
<Main>
    <h3>Pagina Invalida</h3>
    <h6>Clique no menu ao lado para voltar</h6>
    </Main>

export default notFound

