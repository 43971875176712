import axios from "axios";
import moment from "moment";
import { apiPix } from "../service/api";

const dataAtual = moment().format("YYYY-MM-DD HH:mm:ss");
const inscricao = sessionStorage.getItem("inscricao");
const IPClientes = sessionStorage.getItem("IPCliente");

let detectaNavegador,
  sUsrAg = navigator.userAgent;

if (sUsrAg.indexOf("Firefox") > -1) {
  detectaNavegador = "Mozilla Firefox";
} else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
  detectaNavegador = "Samsung Internet";
} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
  detectaNavegador = "Opera";
} else if (sUsrAg.indexOf("Edge") > -1) {
  detectaNavegador = "Microsoft Edge";
} else if (sUsrAg.indexOf("Chrome") > -1) {
  detectaNavegador = "Google Chrome";
} else if (sUsrAg.indexOf("Safari") > -1) {
  detectaNavegador = "Apple Safari";
} else {
  detectaNavegador = "Não identificado";
}

export async function IPCliente() {
  await axios
    .get("https://api.ipify.org")
    .then((res) => {
      sessionStorage.setItem("IPCliente", res.data);
    })
    .catch((err) => {
      throw new Error(err);
    });
}
IPCliente();

let body = {
  inscricao: inscricao,
  ip: IPClientes,
  navegador: detectaNavegador,
  dataHoraAcesso: dataAtual,
};

export async function enviaLogsAcesso(inscricaoCliente, ipCliente) {
  try {
    body.inscricao = inscricaoCliente;
    body.ip = ipCliente;
    const result = await apiPix.post("/log-area-cliente", body);
    return result.data.result;
  } catch (error) {
    return error;
  }
}