import React, { useState, useEffect } from 'react';
import styles from './style.module.css'

import { InputText } from 'primereact/inputtext';
import copy from '../../assets/imgs/copy.png'
import Swal from 'sweetalert2'
import { apiPix } from '../service/api';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment'
import { formatCurrency } from '../../utils/format';


export function Pix ({dadosParcelas}){

    const {Vencimento, Valor, Referencia, Inscricao} = dadosParcelas;
    const [viwsQrCode, setViwsQrCode] = useState(false);
    const [qrValue, setQrValue] = useState('');
    const {image, code, valor} = qrValue;
    const valorFormatado = formatCurrency(parseFloat(valor));


    const parcelas = {
        parcelas: [
            {
                "Referencia": Referencia,
                "Valor": parseFloat(Valor),
                "Vencimento" : moment(Vencimento,["DD/MM/YYYY"]).format('YYYY-MM-DD')
            }
        ]
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await apiPix.post(`/pagamentos/pix/${Inscricao}`, parcelas);
                setQrValue(response.data);
                setViwsQrCode(true);
            } catch (error) {
                return error.message;
            }
        })();
    }, []);

    // copia do numero do qr code par area de transferência
    const copyToClipBoard = () => {
        let text = document.getElementsByClassName(`${styles.input}`)[0];
        text.select();
        document.execCommand('copy');
        successAlert();
    };
 
    const successAlert = () => {
        Swal.fire({
            title: 'Link copiado com sucesso!',
            text: code,
            icon: 'success',
            confirmButtonColor: '#1E90FF'
        });
    };
 
    return (
        <>
            {viwsQrCode ?(
                <>
                    <div>
                        <h6 className= {styles.h6} >Valor mensalidade {valorFormatado} referente ao mês {Referencia}</h6>

                    </div>
                        <img className = {styles.imgQr}  alt='Qr code' src={image} />
                       
                    <div className={styles.copy_pix} >
                        <InputText
                            type="text"
                            className= {styles.input}
                            name="pix"
                            value= {code}
                            onChange={e => this.updatedField(e)}

                        />
                        <button className = {styles.button_copy}  type="submit" onClick={() => copyToClipBoard()}>
                            <img src={copy} alt="copy imagem" />
                        </button>
   
                    </div>
                   
                </>
            ):(
                <div className = {styles.containerLoading}>
                    <ProgressSpinner className = {styles.loading} />
                </div>
            )}

        </>

    );
};


