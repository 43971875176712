import React, { Component } from 'react'
import Main from '../template/Main'
import InputMask from "react-input-mask";
import { ProgressSpinner } from 'primereact/progressspinner';
import { showMessageSuccess, showMessageError, showMessageAlert } from '../others/toastr'
import { Button } from 'primereact/button';
import { api, inscricao } from '../service/api'
import { ListBox } from 'primereact/listbox'
import { Dropdown } from 'primereact/dropdown';
import Modal from 'react-bootstrap/Modal'
import moment from 'moment';
import { ToggleButton } from 'primereact/togglebutton';
import backE from '../service/backEnd'

const initialState = {
    dependentes: {
        Seq: '', Nome: '', DepSexo: { value: '' }, Parentesco: { value: '' }, CPF: '', Nascimento: '',
        Telefone: '', Email: '', 'CategoriaParentesco.Valor': '', 'CategoriaParentesco.Categoria': ''
    },
    dados: [],
    loading: true,
    showForm: false,
    showEdit: false,
    showAdd: false,
    showInfo: false,
    showConf: false,
    confirmar: false,
    associado: [],
    abertas: [],
    seqDep: null
}

const options = [
    { DepSexo: 'Masculino', value: 'Masculino' },
    { DepSexo: 'Feminino', value: 'Feminino' }
];

const parent = [
    { value: 'MAE' },
    { value: 'PAI' },
    { value: 'IRMAO' },
    { value: 'IRMA' },
    { value: 'FILHO' },
    { value: 'FILHA' },
    { value: 'NETO(A)' },
    { value: 'NETA' },
    { value: 'SOGRO' },
    { value: 'SOGRA' },
    { value: 'MARIDO' },
    { value: 'ESPOSA' },
    { value: 'TIO(A)' },
    { value: 'TIA' },
    { value: 'SOBRINHO' },
    { value: 'SOBRINHA' },
    { value: 'NORA' },
    { value: 'GENRO' },
    { value: 'AVÔ' },
    { value: 'AVÓ' },
    { value: 'OUTROS' }
];

export default class Dependentes extends Component {

    state = {
        ...initialState
    }

    componentWillMount() {
        api.get(`/dependentes/${inscricao}`)
            .then(result => {
                this.setState({ dados: result.data, loading: false })
            }).catch(err => {
                this.setState({ loading: true })
            })
        api.get(`/associados/${inscricao}`)
            .then(resp => {
                this.setState({ associado: resp.data[0] })
            }).catch(err => {
            })
        api.get(`/mensalidades/abertas/${inscricao}`)
            .then(res => {
                this.setState({ abertas: res.data })
            })
    }

    clear() {
        this.setState({ dependentes: initialState.dependentes })
    }

    save() {
        var save = this.state.dependentes
        delete save.Inscricao
        delete save['CategoriaParentesco.Categoria']
        delete save['CategoriaParentesco.Valor']
        if (save.Seq) {
            if (save.Email === null || save.Email === "") {
                delete save.DepSexo
                delete save.Parentesco
                delete save.Nome
                delete save.Nascimento
                delete save.CPF
                delete save.Email
                delete save['CategoriaParentesco.Categoria']
                delete save['CategoriaParentesco.Valor']
            }
            if (save.Telefone === null || save.Telefone === "") {
                delete save.DepSexo
                delete save.Parentesco
                delete save.Nome
                delete save.Nascimento
                delete save.CPF
                delete save.Telefone
                delete save['CategoriaParentesco.Categoria']
                delete save['CategoriaParentesco.Valor']
            }
            delete save.DepSexo
            delete save.Parentesco
            delete save.Nome
            delete save.Nascimento
            delete save.CPF
            delete save['CategoriaParentesco.Categoria']
            delete save['CategoriaParentesco.Valor']
            api.put(`/dependentes/${inscricao}`, save)
                .then(resp => {
                    const dados = this.getUpdatedList(resp.data)
                    showMessageSuccess("Sucesso", "Dependente modificado com sucesso!")
                    setTimeout(this.reload, 3000);
                }).catch(err => {
                    console.error(err);
                    if (err === null) {
                        return
                    }
                    if (err.response.status === 406) {
                        showMessageError("Erro", `Dependente Modificado há menos de 6 meses! Erro ${err.response.status}`)
                        setTimeout(this.reload, 3000);
                    } if (err.response.status === 422) {
                        showMessageAlert("Campo Vazio", `Preencha o campo ${err.response.data.error.details[0].path} para continuar.`)
                    } if (err.response.status === 500) {
                        showMessageError("Erro", `Por Favor tente novamente!`)
                    }
                })
        } else {
            delete save.Seq
            if (this.state.confirmar === false) {
                { this.handleOpenConf() }
                { this.renderModalConf() }
            } if (this.state.confirmar === true) {
                moment.locale('pt-BR')
                save.Nascimento = moment(this.state.dependentes.Nascimento, ["DD/MM/YYYY"]).format('YYYY-MM-DD');
                this.state.dependentes.Parentesco = this.campoValue(this.state.dependentes.Parentesco)
                this.state.dependentes.DepSexo = this.campoValue(this.state.dependentes.DepSexo)
                api.post(`/dependentes/${inscricao}`, save)
                    .then(resp => {
                        backE.post('/depenviar', {
                            "user": this.state.associado.AssEmail,
                            "name": this.state.associado.Nome,
                            "valor": this.enviarDep(),
                            "depen": save.Nome,
                            "inscricao": inscricao
                        }).then(resp => {
                        }).catch(err => {
                        })
                        const dados = this.getUpdatedList(resp.data)
                        this.setState({ save: initialState.dependentes, dados })
                        showMessageSuccess("Sucesso", "Dependente adicionado com sucesso!")
                        setTimeout(this.reload, 3000);
                    }).catch(err => {
                        if (err.response.status === 422) {
                            showMessageError("Erro ao Salvar", `Preencha todos os campos! Erro ${err.response.data.error.details[0].path}`)
                            save.Nascimento = moment(this.state.dependentes.Nascimento).format('DD/MM/YYYY');
                        } else {
                            showMessageError("Erro", `Por Favor tente novamente! ${err.response.status}`)
                            setTimeout(this.reload, 3000);
                        }
                    })
            }
        }
    }

    enviarDep() {

        if (this.state.associado.SubGrupo === "ZS   " && this.dataCac(this.state.dependentes.Nascimento) <= 64) {
            return "R$ 5,00"
        } if (this.state.associado.SubGrupo === "ZS   " && this.dataCac(this.state.dependentes.Nascimento) >= 65) {
            return "R$ 15,00"
        }

        if (this.dataCac(this.state.dependentes.Nascimento) <= 64) {
            return "R$ 3,00"
        } if (this.dataCac(this.state.dependentes.Nascimento) >= 65) {
            return "R$ 10,00"
        }
    }

    campoValue(campo) {
        if (campo.value) {
            campo = campo.value
            return campo
        } else if (campo) {
            campo = campo
            return campo
        }
    }

    getUpdatedList(dependentes, add = true) {
        const dados = this.state.dados.filter(d => d.Seq !== dependentes.Seq)
        if (add) dados.unshift(dependentes)
        return dados
    }

    updatedField(event) {
        const dependentes = { ...this.state.dependentes }
        dependentes[event.target.name] = event.target.value
        this.setState({ dependentes })
    }

    updatedFieldDoc(event) {
        const dependentes = { ...this.state.dependentes }
        dependentes[event.target.name] = event.target.value
        this.setState({ dependentes })
    }

    handleCloseModalForm() {
        this.setState({ showForm: false })
    }

    handleOpenModalForm(dependentes) {
        showMessageAlert("Alerta", "Alteração feita somente pelo titular!")
        const dadosAssociado = this.state.associado
        if (dadosAssociado.AssEmail === null) {
            showMessageAlert("Alerta", "Você precisa de um e-mail cadastrado para continuar essa ação!")
            return
        }
        if (dadosAssociado.Celular === null) {
            showMessageAlert("Alerta", "Você precisa de um Celular cadastrado para continuar essa ação!")
            return
        }
        const getdate = moment().toDate()
        const horam = moment(getdate).format('DD/MM/YYYY')
        const comparar = this.state.abertas
        var valor = 0
        var mescomprep = comparar[0].Vencimento.replace(/\D+/g, '')
        var mescomp = mescomprep.substring(2, 4)
        comparar.forEach(values => {

            var horamm = horam.replace(/\D+/g, '');
            var diahoram = horamm.substring(0, 2);
            var meshoram = horamm.substring(2, 4)
            var anohoram = horamm.substring(4, 8)
            var valorhoram = anohoram + meshoram + diahoram

            var vencimentoo = values.Vencimento.replace(/\D+/g, '');
            var diavenc = vencimentoo.substring(0, 2);
            var mesvenc = vencimentoo.substring(2, 4);
            var anovenc = vencimentoo.substring(4, 8);
            var valorvenci = anovenc + mesvenc + diavenc

            var mesdep = mescomp - meshoram

            if (valorvenci < valorhoram || mesdep >= 3) {
                return valor = 1
            }
        })
        if (valor === 1) {
            showMessageError("Alteração Contratual Indisponível no momento!", `
            Alteração Contratual Indisponível no momento
            Verifique se todas as parcelas do plano estão
            devidamente em dia no campo PARCELAS,
            para mais informações ligar para central de Atendimento
            Grupo Zelo segunda sábado 07:00 as 20:30h
            (31)3314-8000.
            `)
            return
        }
        if (valor === 0) {
            this.setState({ showForm: true })
            this.setState({ seqDep: dependentes })
        }
    }

    handleCloseEdit() {
        window.location.reload();
        this.setState({ showEdit: false })
    }

    handleOpenEdit() {
        showMessageAlert("Alerta", "Alteração feita somente pelo titular!")
        const dadosAssociado = this.state.associado
        if (dadosAssociado.AssEmail === null) {
            showMessageAlert("Alerta", "Você precisa de um e-mail cadastrado para continuar essa ação!")
            return
        }
        if (dadosAssociado.Celular === null) {
            showMessageAlert("Alerta", "Você precisa de um Celular cadastrado para continuar essa ação!")
            return
        }
        const getdate = moment().toDate()
        const horam = moment(getdate).format('DD/MM/YYYY')
        const comparar = this.state.abertas
        var valor = 0
        var mescomprep = comparar[0].Vencimento.replace(/\D+/g, '')
        var mescomp = mescomprep.substring(2, 4)
        comparar.forEach(values => {

            var horamm = horam.replace(/\D+/g, '');
            var diahoram = horamm.substring(0, 2);
            var meshoram = horamm.substring(2, 4)
            var anohoram = horamm.substring(4, 8)
            var valorhoram = anohoram + meshoram + diahoram

            var vencimentoo = values.Vencimento.replace(/\D+/g, '');
            var diavenc = vencimentoo.substring(0, 2);
            var mesvenc = vencimentoo.substring(2, 4);
            var anovenc = vencimentoo.substring(4, 8);
            var valorvenci = anovenc + mesvenc + diavenc

            var mesdep = mescomp - meshoram

            if (valorvenci < valorhoram || mesdep >= 3) {
                return valor = 1
            }
        })
        if (valor === 1) {
            showMessageError("Alteração Contratual Indisponível no momento!", `
            Alteração Contratual Indisponível no momento
            Verifique se todas as parcelas do plano estão
            devidamente em dia no campo PARCELAS,
            para mais informações ligar para central de Atendimento
            Grupo Zelo segunda sábado 07:00 as 20:30h
            (31)3314-8000.
            `)
            return
        }
        if (valor === 0) {
            this.setState({ showEdit: true })
        }
    }

    handleCloseAdd() {
        this.setState({ showAdd: false })
        window.location.reload();
    }

    handleOpenAdd() {
        showMessageAlert("Alerta", "Alteração feita somente pelo titular!")
        const dadosAssociado = this.state.associado
        if (dadosAssociado.AssEmail === null) {
            showMessageAlert("Alerta", "Você precisa de um e-mail cadastrado para continuar essa ação!")
            return
        }
        if (dadosAssociado.Celular === null) {
            showMessageAlert("Alerta", "Você precisa de um Celular cadastrado para continuar essa ação!")
            return
        }
        const getdate = moment().toDate()
        const horam = moment(getdate).format('DD/MM/YYYY')
        const comparar = this.state.abertas
        var valor = 0
        var mescomprep = comparar[0].Vencimento.replace(/\D+/g, '')
        var mescomp = mescomprep.substring(2, 4)
        comparar.forEach(values => {

            var horamm = horam.replace(/\D+/g, '');
            var diahoram = horamm.substring(0, 2);
            var meshoram = horamm.substring(2, 4)
            var anohoram = horamm.substring(4, 8)
            var valorhoram = anohoram + meshoram + diahoram

            var vencimentoo = values.Vencimento.replace(/\D+/g, '');
            var diavenc = vencimentoo.substring(0, 2);
            var mesvenc = vencimentoo.substring(2, 4);
            var anovenc = vencimentoo.substring(4, 8);
            var valorvenci = anovenc + mesvenc + diavenc

            var mesdep = mescomp - meshoram


            if (valorvenci < valorhoram || mesdep >= 3) {
                return valor = 1
            }
        })
        if (valor === 1) {
            showMessageError("Alteração Contratual Indisponível no momento!", `
            Alteração Contratual Indisponível no momento
            Verifique se todas as parcelas do plano estão
            devidamente em dia no campo PARCELAS,
            para mais informações ligar para central de Atendimento
            Grupo Zelo segunda sábado 07:00 as 20:30h
            (31)3314-8000.
            `)
            return
        }
        if (valor === 0) {
            this.setState({ showAdd: true })
        }
    }

    handleOpenInfo() {
        this.setState({ showInfo: true })
    }
    handleCloseInfo() {
        this.setState({ showInfo: false })
    }

    handleOpenConf() {
        this.setState({ showConf: true })
    }
    handleCloseConf() {
        this.setState({ showConf: false })
    }

    mensagemDep() {
        const paren = this.state.dependentes.Parentesco.value
        if (paren === null || paren === undefined) {
            return
        }
        if (paren === null || paren === undefined) {
            return
        }
        if (this.state.associado.SubGrupo === "ZS   " && paren === "PAI" | paren === "MAE" | paren === "SOGRO" | paren === "SOGRA" | paren === "FILHO" | paren === "FILHA"
            | paren === "MARIDO" | paren === "ESPOSA") {
            return (
                <div>A partir de agora <b>{this.state.dependentes.Nome}</b> estará incluso no seu plano.</div>
            )
        } if (this.state.associado.SubGrupo === "ZF   " && paren === "PAI" | paren === "MAE" | paren === "SOGRO" | paren === "SOGRA" | paren === "FILHO" | paren === "FILHA"
            | paren === "MARIDO" | paren === "ESPOSA") {
            return (
                <div>A partir de agora <b>{this.state.dependentes.Nome}</b> estará incluso no seu plano.</div>
            )
        }
        else {
            return (
                <div>A adição de dependentes vai gerar uma cobrança de <b>{this.CalcIdade(this.state.dependentes.Nascimento)}</b> em sua mensalidade.</div>
            )
        }
    }

    renderModalConf() {
        return (
            <Modal show={this.state.showConf} onHide={() => this.handleCloseConf()}>
                <Modal.Header closeButton>
                    <Modal.Title><h6>{this.mensagemDep()}</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body closeButton>
                    <div className="container">
                        Deseja  continuar?
                        <ToggleButton style={{ width: '150px', left: '10px' }} onLabel="Confirmado"
                            offLabel="Continuar"
                            checked={this.state.confirmar}
                            onChange={(e) => this.setState({ confirmar: e.value }) & setTimeout(this.handleCloseConf(), 1000) & showMessageAlert("Clique em salvar para Continuar!")} />
                    </div>
                </Modal.Body>
            </ Modal>
        )
    }

    CalcIdade() {
        const paren = this.state.dependentes.Parentesco.value
        if (this.state.associado.SubGrupo === "ZF   " && paren === "PAI" | paren === "MAE" | paren === "SOGRO" | paren === "SOGRA" | paren === "FILHO" | paren === "FILHA"
            | paren === "MARIDO" | paren === "ESPOSA") {
            this.dataCac(moment(this.state.dependentes.Nascimento, ["DD/MM/YYYY"]).format('YYYY-MM-DD'))
            return (
                "R$ 0,00"
            )
        }
        if (this.state.associado.SubGrupo === "ZS   " && paren === "PAI" | paren === "MAE" | paren === "SOGRO" | paren === "SOGRA" | paren === "FILHO" | paren === "FILHA"
            | paren === "MARIDO" | paren === "ESPOSA") {
            this.dataCac(moment(this.state.dependentes.Nascimento, ["DD/MM/YYYY"]).format('YYYY-MM-DD'))
            return (
                "R$ 0,00"
            )
        }
        // if (paren === "PAI" | paren === "MAE" | paren === "SOGRO" | paren === "SOGRA" | paren === "FILHO" | paren === "FILHA"
        // | paren === "MARIDO" | paren === "ESPOSA")
        // // & this.state.dependentes.Parentesco === "PAI", "MAE", "SOGRO", "SOGRA", "FILHO","FILHA", "MARIDO", "ESPOSA"
        // delete save.Seq
        // if (this.state.associado.Inscricao === 40743) {
        // this.state.associado.SubGrupo === "ZE"  &
        //     this.setState({ confirmar: true })
        //     this.setState({ showConf: false })
        // }
        if (this.state.associado.SubGrupo === "ZS   " && this.dataCac(moment(this.state.dependentes.Nascimento, ["DD/MM/YYYY"]).format('YYYY-MM-DD')) <= 64) {
            return (
                "R$ 5,00"
            )
        }
        if (this.state.associado.SubGrupo === "ZS   " && this.dataCac(moment(this.state.dependentes.Nascimento, ["DD/MM/YYYY"]).format('YYYY-MM-DD')) >= 65) {
            return (
                "R$ 15,00"
            )
        }
        if (this.dataCac(moment(this.state.dependentes.Nascimento, ["DD/MM/YYYY"]).format('YYYY-MM-DD')) <= 64) {
            return (
                "R$ 3,00"
            )
        } if (this.dataCac(moment(this.state.dependentes.Nascimento, ["DD/MM/YYYY"]).format('YYYY-MM-DD')) >= 65) {
            return (
                "R$ 10,00"
            )
        }

    }

    dataCac(dateString) {
        var birthday = +new Date(dateString);
        return ~~((Date.now() - birthday) / (31557600000));

    }

    renderModalInfo() {
        return (
            <>
                <Modal show={this.state.showInfo} onHide={() => this.handleCloseInfo()}>
                    <Modal.Header closeButton>
                        <Modal.Title><b>Regras Adicionar Dependentes</b></Modal.Title>
                    </Modal.Header>
                    <div className="container"><b>Carência funerária de 180 dias para novos dependentes</b></div>
                    <Modal.Body closeButton style={{ margin: '10px' }}>
                        <div>
                            <div className="row">
                                <b>Plano total :</b>
                                <ul>
                                    <li>Até 14 dependentes com ou sem grau de parentesco</li>
                                    <li>Dependente até 64 anos: + 3 reais</li>
                                    <li>Dependente acima de 65: + 10 reais</li>
                                </ul>
                            </div>
                            <div className="row">
                                <b> Plano Família:</b>
                                <ul>
                                    <li> Nativo do plano: Cônjuge, pais, sogros e filhos do titular - Ilimitado - grátis</li>
                                    <li> Especiais: Avós, irmãos, netos, tios, sobrinhos, ex-cônjuge, genros e noras - máximo 14</li>
                                    <li> Dependente  Especiais até 64 anos: + 3 reais</li>
                                    <li> Dependente  Especiais acima de 65: + 10 reais</li>
                                </ul>
                            </div>
                            <div className="row">
                                <b>Plano supremo</b>
                                <ul>
                                    <li> Nativo do plano: Cônjuge, pais, sogros e filhos do titular - Ilimitado - grátis</li>
                                    <li>Especiais: Avós, irmãos, netos, tios, sobrinhos, ex-cônjuge, genros e noras - máximo 14</li>
                                    <li>Dependente  Especiais até 64 anos: + 5 reais</li>
                                    <li>Dependente  Especiais acima de 65: + 15 reais</li>
                                </ul>
                            </div>
                        </div>
                    </ Modal.Body>
                </Modal>
            </>
        )
    }

    renderForm() {
        return (
            <>
                <Modal show={this.state.showEdit} onHide={() => this.handleCloseEdit()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Dependente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body closeButton>
                        <div className="form" style={{ heigh: 'auto' }}>
                            <div className="row"><h5>Editar dados do dependente</h5></div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label>Email</label>
                                        <input type="text" className="form-control" name="Email" value={this.state.dependentes.Email} onChange={e => this.updatedField(e)} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label>Telefone</label>
                                        <InputMask mask="99999999999" type="text" className="form-control" name="Telefone" value={this.state.dependentes.Telefone} onChange={e => this.updatedField(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <Button label="Salvar" className="p-button-raised p-button-info" onClick={e => this.save(e)}></Button>
                                    <Button label="Limpar" className="p-button-raised p-button-secondary" onClick={e => this.clear(e)} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {this.renderTable()}
            </>
        )
    }

    renderAddForm() {
        return (
            <>
                <Modal show={this.state.showAdd} onHide={() => this.handleCloseAdd()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Adicionar Dependente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body closeButton>
                        <div className="form" style={{ heigh: 'auto' }}>
                            <div className="row"><h6>Preencha os campos para adicionar um novo Dependente</h6></div>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="form-grupo">
                                        <label>Nome</label>
                                        <input type="text" className="form-control" name="Nome" value={this.state.dependentes.Nome}
                                            onChange={e => this.updatedField(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label>Email</label>
                                        <input type="text" className="form-control" name="Email" value={this.state.dependentes.Email} onChange={e => this.updatedField(e)} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label>Telefone</label>
                                        <InputMask mask="99999999999" type="text" className="form-control" name="Telefone" value={this.state.dependentes.Telefone} onChange={e => this.updatedField(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label>Sexo</label>
                                        <ListBox

                                            value={this.state.dependentes.DepSexo || ''}
                                            style={{ heigh: 'auto' }}
                                            name="DepSexo"
                                            options={options}
                                            onChange={(e) => this.updatedFieldDoc(e)}
                                            optionLabel="DepSexo" optionValue="value" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label>CPF</label>
                                        <InputMask mask="999.999.999.99" type="text" className="form-control" name="CPF" value={this.state.dependentes.CPF} onChange={e => this.updatedField(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label>Parentesco</label>
                                        <Dropdown
                                            optionLabel="value" optionValue="value"
                                            value={this.state.dependentes.Parentesco || ''}
                                            style={{ heigh: 'auto' }} name="Parentesco"
                                            options={parent}
                                            onChange={(e) => {
                                                this.updatedFieldDoc(e)
                                            }}
                                            onMouseDown={(e) => {
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label>Nascimento</label>
                                        <InputMask mask="99/99/9999" type="text" className="form-control" name="Nascimento" value={this.state.dependentes.Nascimento} onChange={e => this.updatedField(e)} />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <Button label="Salvar" className="p-button-raised p-button-info" onClick={e => this.save(e)}></Button>
                                    <Button label="Limpar" className="p-button-raised p-button-secondary" onClick={e => this.clear(e)} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

    reload() {
        window.location.reload();
    }
    removedepen(data) {
        if (this.dataCac(moment(data, ["DD/MM/YYYY"]).format('YYYY-MM-DD')) <= 64) {
            return "R$ 3,00"
        } if (this.dataCac(moment(data, ["DD/MM/YYYY"]).format('YYYY-MM-DD')) >= 65) {
            return "R$ 10,00"
        }
    }

    async remove(dependentes) {
        const param = dependentes.Seq
        const { Seq } = dependentes;
        const nomee = dependentes.Nome

        await api.delete(`/dependentes/${inscricao}`, {
            data: { Seq: param }
        })
            .then(async resp => {
                await backE.post('/removedep', {
                    "user": this.state.associado.AssEmail,
                    "name": this.state.associado.Nome,
                    "valor": this.removedepen(dependentes.Nascimento),
                    "inscricao": inscricao,
                    "depen": nomee
                }).then(resp => {
                }).catch(err => {
                    showMessageError("Error", "Erro ao enviar requisição!")
                })
                // const dados = this.getUpdatedList(dependentes, false)
                // this.setState({ dados })
                showMessageSuccess("Sucesso", `Dependente Excluido.`)
            }).catch(err => {
                showMessageError("Erro", `Alteração inferior a 6 meses.`)
            })
        setTimeout(this.reload, 3000);
    }

    load(dependentes) {
        this.setState({ dependentes })
    }

    renderTable() {
        return (
            <table className="table mt-4">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Email</th>
                        <th>Telefone</th>
                        <th>Nascimento</th>
                        <th>Sexo</th>
                        <th>Parentesco</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>

            </table>
        )
    }

    renderRows() {
        return this.state.dados.map(dependentes => {
            return (
                <>
                    <Modal show={this.state.showForm} onHide={() => this.handleCloseModalForm()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Exclusão de Dependente</Modal.Title>
                        </Modal.Header>
                        <Modal.Body closeButton>
                            Tem Certeza que Deseja Excluir o Dependente?
                            <br />
                            <button className="btn btn-danger ml-2" style={{ width: 'auto' }} onClick={() => this.remove(this.state.seqDep) & this.handleCloseModalForm()}>
                                Sim
                            </button>
                            <button className="btn btn-warning ml-2" style={{ width: 'auto' }} onClick={e => this.handleCloseModalForm()}>
                                Não
                            </button>
                        </Modal.Body>
                    </Modal>
                    <tr style={{ width: '100%' }}>
                        <td>{dependentes.Nome}</td>
                        <td>{dependentes.CPF}</td>
                        <td>{dependentes.Email}</td>
                        <td>{dependentes.Telefone}</td>
                        <td>{dependentes.Nascimento}</td>
                        <td>{dependentes.DepSexo}</td>
                        <td>{dependentes.Parentesco}</td>
                        <td style={{ width: '100%' }}>
                            <button className="btn btn-warning" style={{ width: 'auto' }} onClick={() => this.load(dependentes) & this.handleOpenEdit()}>
                                <i className="fa fa-pencil"></i>
                            </button>
                            <button className="btn btn-danger ml-2" style={{ width: 'auto' }} onClick={e => this.handleOpenModalForm(dependentes)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </>
            )
        })
    }

    render() {
        return (
            <Main>
                <div className='container'><h5><b>Dependentes do Plano</b></h5></div>
                <div><Button style={{ marginBottom: '5px' }} label="Adicionar Dependentes" className="p-button-raised p-button-info" onClick={() => this.handleOpenAdd()}></Button></div>
                <Button label="Regras Dependentes" className="p-button-raised p-button-info" onClick={() => this.handleOpenInfo()}></Button>
                {this.renderAddForm()}
                {this.renderModalInfo()}
                <hr />
                {this.renderModalConf()}
                {this.state.loading ? <ProgressSpinner style={{ width: '50px', height: '50px', marginLeft: '50%' }} /> : this.renderForm()}
            </Main>
        )
    }
}