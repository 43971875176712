import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AdyenCheckout from '@adyen/adyen-web';
import moment from 'moment';
import { Messages } from 'primereact/messages';
import { ProgressSpinner } from 'primereact/progressspinner';
import { apiAdyen } from '../service/apiAdyen';
import { getValue } from '../../utils/hash';

const keyAdyen = getValue(process.env.REACT_APP_6);
const ambienteAdyen = getValue(process.env.REACT_APP_8);

const AdyenPopUp = (props) => {
    const [loading, setLoading] = useState(false)

    const dropinContainerRef = useRef();
    const voucherContainerRef = useRef();
    const messages = useRef(null);

    const showError = detail => {
        messages.current.show({
            severity: 'error',
            summary: 'Ups! Ocorreu um erro.',
            detail:
                detail || 'Erro ao cadastrar cartão. Tente novamente!',
        });
    }

    const showSuccess = () => {
        messages.current.show({
            severity: 'success',
            summary: 'Parabéns!',
            detail: 'Cartão cadastrado com sucesso!',
        });
    }

    useEffect(() => {
        const main = async () => {
            try {
                setLoading(true)
                const clientKey = keyAdyen;

                const paymentMethods = (await apiAdyen.post('/list', {
                    value: `${props.Associado.Valor}`,
                    blockedPayment: ['boletobancario'],
                })).data

                const configuration = {
                    paymentMethodsResponse: paymentMethods,
                    clientKey,
                    locale: 'pt-BR',
                    environment: ambienteAdyen,
                    onSubmit: async (state, dropin) => {
                        checkout.remove(dropin);
                        setLoading(true)
                        messages.current.show({
                            severity: 'warning',
                            summary: 'Aguarde, estamos confirmando seu pagamento',
                            life: 2000
                        });
                        const data = {
                            paymentData: state.data, // Dados para gerar o boleto
                            reference: props.Associado.Referencia, // Referencia da mensalidade
                            inscricao: props.Associado.Inscricao, // Inscrição dos associados
                            deliveryDate: moment().add(3, 'days').toISOString(), // Data de vencimento do boleto
                            value: `${props.Associado.Valor}`,
                        };

                        const cartao = (await apiAdyen.post('/cartao', data)).data

                        switch (cartao.resultCode) {
                            case 'Error':
                            case 'Refused':
                            case 'Cancelled':
                                showError()
                                break;

                            case 'Authorised':
                                const { amount, additionalData, merchantReference, pspReference } = cartao
                                const { paymentMethod } = additionalData
                                const eventDate = new Date()
                                const merchantAccountCode = getValue(process.env.REACT_APP_7)
                                const infosParaDarBaixa = {
                                    live: ambienteAdyen === "live" ? "true" : "false",
                                    notificationItems: [
                                        {
                                            NotificationRequestItem: {
                                                additionalData,
                                                amount,
                                                eventCode: "AUTHORISATION",
                                                eventDate,
                                                merchantAccountCode,
                                                merchantReference,
                                                paymentMethod,
                                                pspReference,
                                                success: "true"
                                            }
                                        }
                                    ]
                                }

                                await apiAdyen.post('webhooks', infosParaDarBaixa)
                                showSuccess()
                                break
                            default:
                                showSuccess()
                                break;
                        }

                        setTimeout(() => {
                            setLoading(false)
                            window.location.reload();
                        }, 2000);
                    },
                    onChange: (error) => {
                        console.error(error);
                    },
                    onError: (error) => {
                        console.error(error);
                    },
                };

                const defineParcelaPlanoZP = (SubGrupo) => {
                    if (SubGrupo?.trim() === "ZP4" || SubGrupo?.trim() === "ZPC4") {
                        return [1, 2, 3, 4];
                    }

                    return [1];
                };

                const checkout = new AdyenCheckout(configuration);
                checkout
                    .create('dropin', {
                        showPayButton: true,
                        amount: {
                            currency: 'BRL',
                            value: `${props.Associado.Valor}`,
                        },
                        paymentMethodsConfiguration: {
                            card: {
                                enableStoreDetails: false,
                                installmentOptions: {
                                    card: {
                                        values: defineParcelaPlanoZP(props.Associado.SubGrupo),
                                        plans: ['regular'],
                                    },
                                    showInstallmentAmounts: true,
                                },
                            },
                        },
                    })
                    .mount(dropinContainerRef.current);
            } catch (error) {
                showError('tente novamente!')
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } finally {
                setLoading(false)
            }
        };
        main();
    }, [props]);

    return (
        <>
            <Messages ref={messages} />
            {loading && <ProgressSpinner style={{
                width: '50px', height: '50px',
                marginLeft: '50%'
            }} />}
            <div
                ref={dropinContainerRef}
                id="dropin-container"
            />
            <div ref={voucherContainerRef} />
        </>
    );
};

AdyenPopUp.propTypes = {
    ReturnUrl: PropTypes.string.isRequired,
    Associado: PropTypes.shape({
        Inscricao: PropTypes.number.isRequired,
        Valor: PropTypes.number.isRequired,
        TipoAceite: PropTypes.number.isRequired,
    }).isRequired,
};

export default AdyenPopUp;
