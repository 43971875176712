import React, { useEffect, useState } from "react";
import Main from "../template/Main";
import { ProgressSpinner } from "primereact/progressspinner";
import { showMessageError } from "../others/toastr";
import { inscricao, apiPix as apiVendas } from "../service/api";
import "./alterarDados.css";
import AlterarDadosForm from "./alterarDadosForm";
import {
  atualizacaoCadastral,
  preparadaAtualizacaoCadastral,
} from "../atualizacaoCadastral/infraestrutura/atualizacaoCadastral";
import { useAssociateContext } from "../../context/AssociateContext";

export default function AlterarDados() {
  const [isLoadingDados, setIsLoadingDados] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [erroDeComunicacao, setErroDeComunicacao] = useState(false);
  const [dados, setDados] = useState({});
  const { associadoContext } = useAssociateContext();

  useEffect(() => {
    buscaInfosAssociado();
  }, [inscricao]);

  async function buscaInfosAssociado() {
    try {
      setIsLoadingDados(true);

      const res = await apiVendas.get(
        `/associados/${inscricao ?? sessionStorage.getItem("inscricao")}`
      );

      const {
        Nome,
        CEPResid,
        Residencia,
        numeroResidencia,
        Bairro,
        Telefone,
        Celular,
        AssEmail,
        RG,
        CPF,
      } = res.data[0];

      setDados({
        Nome: Nome || "",
        CEPResid: CEPResid || "",
        Residencia: Residencia || "",
        numeroResidencia: numeroResidencia || "",
        Bairro: Bairro || "",
        Telefone: Telefone || "",
        Celular: Celular || "",
        AssEmail: AssEmail || "",
        RG: RG || "",
        CPF: CPF || "",
      });
    } catch (error) {
      setErroDeComunicacao(true);
      showMessageError(
        "Erro de comunicação com servidor!",
        "tente novamente mais tarde."
      );
    } finally {
      setIsLoadingDados(false);
    }
  }

  async function onSubmit(values) {
    setIsLoadingSubmit(true);

    const dadosAtualizacaoCadastral = preparadaAtualizacaoCadastral(
      values,
      associadoContext
    );

    await atualizacaoCadastral({
      inscricao: inscricao ?? sessionStorage.getItem("inscricao"),
      ...dadosAtualizacaoCadastral,
    });

    setIsLoadingSubmit(false);
  }

  return (
    <Main>
      <div className="container">
        <h5>
          <b>Dados Cadastrais</b>
        </h5>
      </div>
      <hr />
      {isLoadingDados && (
        <ProgressSpinner
          style={{ width: "50px", height: "50px", marginLeft: "50%" }}
        />
      )}
      {!isLoadingDados && !erroDeComunicacao && (
        <AlterarDadosForm
          dados={dados}
          onSubmit={onSubmit}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
      {!isLoadingDados && erroDeComunicacao && (
        <h5 className="erro">
          Erro de comunicação com o servidor, tente novamente mais tarde.
        </h5>
      )}
    </Main>
  );
}
