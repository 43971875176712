import axios from 'axios'
import { getValue } from '../../utils/hash';

const url = getValue(process.env.REACT_APP_1);

const token = getValue(process.env.REACT_APP_2);

const urlContratoApi = getValue(process.env.REACT_APP_3);
const tokenContratoApi = getValue(process.env.REACT_APP_4);

export const api = axios.create({
    baseURL: urlContratoApi,
    headers: { 'x-auth-token': tokenContratoApi }
})

export const inscricao = sessionStorage.getItem('inscricao');

export const apiPix = axios.create({
    baseURL: url,
    headers: {
        'x-auth-token': token,
    }
});
