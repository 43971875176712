import { apiPix as api } from "../api";
//import HttpClient from '../pdfResouce';

class BoletoService {
    async buscarBoleto(inscricao, nossoNumero){
        try {
            const response = await api.get(`pagamentos/boleto/interno/${inscricao}/${nossoNumero}`)
            return {
                status: response.status,
                data: response.data
            }
        } catch (error) {
            return {
                status: error.reponse.status,
                data: {}
            }
        }
    }
    async buscarBoletoGerencianet(nossoNumero){
        try {
            const response = await api.get(`pagamentos/boleto/gerencianet/${nossoNumero}`)
           
            return {
                status: response.status,
                data: response.data
            }
        } catch (error) {
            return {
                status: error.reponse.status,
                data: {}
            }
        }
    }

    async downloadBoleto(body){
        try {
            //const response = await HttpClient.post('pdf-resource/associates/bank-slip/generate', body)
            const response = await api.post(`pagamentos/gerar-boleto-pdf`, body);
            return {
                status: response.status,
                data: response.data
            }
        } catch (error) {
            return {
                status: error.reponse.status,
                data: {}
            }
        }
    }
}


export default new BoletoService()