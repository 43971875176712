import React from 'react';
import home from './component/home/home';
import Boleto from './component/boleto/emissaoboleto';
import QuitacaoBoletos from './component/boleto/quitacaoDebitos';
import BoletosPagos from './component/boleto/extratoBoletosPagos';
import Dependentes from './component/dependentes/dependentes';
import SegundaCarteira from './component/segundavia/viacarteirinha';
import NotFound from './notfound';
import { isAuthenticated } from './component/auth/auth';
import Login from './component/auth/login';
import AlterarDados from './component/setings/alterarDados';
import AlterarPagamento from './component/setings/alterarPagamento';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import helpTicket from './component/setings/helpTicket';
import ImprimirBoleto from './component/boleto/imprimirBoleto';
import Myapp from './component/boleto/pdfQuitacao';
import newDependentes from './component/dependentes/newDependentes';

const PrivatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest}
        render={props => (
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
        )} />
)

export default props =>
    <HashRouter>
        <Switch>
            <Route path="/login" component={Login} />
            <PrivatedRoute exact path="/" component={home} />
            <PrivatedRoute path="/boleto" component={Boleto} />
            <PrivatedRoute path="/boletosPagos" component={BoletosPagos} />
            <PrivatedRoute path="/quitacaoDebitos" component={QuitacaoBoletos} />
            <PrivatedRoute path="/dependentes" component={newDependentes} />
            <PrivatedRoute path="/segundavia/carteira" component={SegundaCarteira} />
            <PrivatedRoute exact path="/setings/alterarDados" component={AlterarDados} />
            <PrivatedRoute exact path="/setings/pagamento" component={AlterarPagamento} />
            <PrivatedRoute exact path="/setings/help" component={helpTicket} />
            <PrivatedRoute exact path="/imprimir/boleto/:origem" component={ImprimirBoleto} />
            <PrivatedRoute exact path="/pdfQuitacao" component={Myapp} />
            <PrivatedRoute component={NotFound} />
            <Redirect from="*" to="/"></Redirect>
        </Switch>
    </HashRouter>