import React, { Component } from 'react'
import Main from '../template/Main'
import axios from 'axios'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputMask } from 'primereact/inputmask'
import { InputTextarea } from 'primereact/inputtextarea';
import { showMessageError } from '../others/toastr'

const initialState = {
    tickets: {
        nome: '', email: '', telefone: '', status: '',
        assunto: '', descricao: ''
    },
    dados: [],
    loading: true
}

const api = axios.create({
    baseURL: "http://localhost:3001"
})

export default class HelpTicket extends Component {

    state = { ...initialState }

    componentDidMount() {
        api.get('/tickets')
            .then(resp => {
                this.setState({ dados: resp.data, loading: false })
            }).catch(err => {
                this.setState({ loading: false })
            })
    }

    save() {
        const save = this.state.tickets
        save.status = "Pendente"
        api.post('/tickets', save)
            .then(resp => {
                const dados = this.getUpdatedList(resp.data)
                this.setState({ save: initialState.tickets, dados })
            }).catch(err => {
                showMessageError("Error", "Erro ao enviar requisição!")
            })
    }

    getUpdatedList(tickets, add = true) {
        const dados = this.state.dados.filter(d => d.id !== tickets.id)
        if (add) dados.unshift(tickets)
        return dados
    }

    onPage(event) {
        this.setState({
            loading: true
        });
        //imitate delay of a backend call
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + this.state.rows;

            this.setState({
                first: startIndex,
                cars: this.datasource.slice(startIndex, endIndex),
                loading: false
            });
        }, 250);
    }

    updatedField(event) {
        const tickets = { ...this.state.tickets }
        tickets[event.target.name] = event.target.value
        this.setState({ tickets })
    }

    renderForm() {
        return (
            <>
                <div className="form">
                    <div className="container"><h6>Formulario do Ticket</h6></div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="form-grupo">
                                <label>Nome</label>
                                <input type="text" className="form-control" name="nome" value={this.state.tickets.nome}
                                    onChange={e => this.updatedField(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-grupo">
                                <label>Email</label>
                                <input type="text" className="form-control" name="email" value={this.state.tickets.email} onChange={e => this.updatedField(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="form-grupo">
                                <label>Telefone</label>
                                <InputMask mask="(99)99999-9999" type="text" className="form-control" name="telefone" value={this.state.tickets.telefone} onChange={e => this.updatedField(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-grupo">
                                <label>Assunto</label>
                                <input type="text" className="form-control" name="assunto" value={this.state.tickets.assunto} onChange={e => this.updatedField(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="form-grupo">
                                <label>Descrição</label>
                                <InputTextarea rows={5} name="descricao" value={this.state.dados.descricao} cols={30} autoResize={true}></InputTextarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <Button label="Salvar" className="p-button-raised p-button-info" onClick={e => this.save(e)}></Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderTable() {
        return (
            <DataTable autoLayout={true} style={{ textAlign: 'center' }} loading={this.state.loading} globalFilter={this.state.globalFilter}
                value={this.state.dados} paginator={true} rows={20} sortField="data" sortOrder={1}>
                <Column field="nome" header="Nome" sortable={false} />
                <Column field="email" header="Email" sortable={false} />
                <Column field="assunto" header="Assunto" sortable={true} />
                <Column field="status" header="Status" sortable={true} />
            </DataTable>
        )
    }

    render() {
        return (
            <Main>
                <div className="container"><h5><b>Ajuda </b></h5></div>
                <hr />
                {this.renderForm()}
                {this.renderTable()}
            </Main>
        )
    }
}