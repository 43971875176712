export const isObjetoVazio = (obj) => {
  return (
    typeof obj === "object" &&
    !Array.isArray(obj) &&
    Object.keys(obj).length === 0
  );
};

export const isObjetosIguais = (objeto1, objeto2) => {
  const chavesObjeto1 = Object.keys(objeto1);
  const chavesObjeto2 = Object.keys(objeto2);

  if (
    chavesObjeto1.length !== chavesObjeto2.length ||
    !chavesObjeto1.every((key) => chavesObjeto2.includes(key))
  ) {
    return false;
  }

  for (const chave of chavesObjeto1) {
    const valorObjeto1 = objeto1[chave];
    const valorObjeto2 = objeto2[chave];

    if (JSON.stringify(valorObjeto1) !== JSON.stringify(valorObjeto2)) {
      return false;
    }
  }

  return true;
};
