import React, { Component } from 'react';
import { Menu } from 'primereact/menu';
import batman from '../../assets/imgs/images.png'
import { logout } from '../auth/auth'

class Nav extends Component {

  constructor() {
    super();
    this.state = {
      items: [
        {
          label: 'Menu',
          items: [{ label: 'Home', icon: 'pi pi-fw pi-home', url: '/' },
          { label: 'Parcelas', icon: 'pi pi-fw pi-copy', command: () => { window.location.hash = "/boleto"; } },
          { label: 'Quitação', icon: 'pi pi-fw pi-pencil', command: () => { window.location.hash = "/quitacaoDebitos"; } },
          { label: 'Dependentes', icon: 'pi pi-fw pi-users', command: () => { window.location.hash = "/dependentes"; } }]
        },
        {
          label: 'Du Benefícios',
          items: [
            { label: 'Du Benefícios Andorid', icon: 'pi pi-android', url: 'https://play.google.com/store/apps/details?id=com.ducash&hl=pt_BR&gl=US' },
            { label: 'Du Benefícios IOS', icon: 'pi pi-apple', url: 'https://apps.apple.com/br/app/pague-com-ducash/id1591472057' },
          ]
        },
        {
          label: 'Minha Conta',
          items: [{ label: 'Dados Cadastrais', icon: 'pi pi-fw pi-money-bill', command: () => { window.location.hash = "/setings/alterarDados"; } },
            { label: 'Ativar cobrança Recorrente', icon: 'pi pi-fw pi-money-bill', command: () => { window.location.hash = "/setings/pagamento"; } },
          { label: 'Sair', icon: 'pi pi-fw pi-power-off', command: () => { logout() } }]
        },
      ]
    };
  }

  render() {
    return (
      <div>
        <aside className="logo">
          <img src={batman} alt="logo" className="batman" style={{ width: "175px", height: "170px" }} />

        </aside>
        <div className="content-section implementation button-demo" style={{ width: "100%", height: "100%" }}>
          <Menu model={this.state.items} />
        </div>



      </div>
    )
  }
}

export default Nav;