import React from "react";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { ErrorMessage, Formik } from "formik";
import "./alterarDados.css";
import {
  retirarNulosCargaFormulario,
  validationSchema,
} from "./helpers/validationSchema";

export default function AlterarDadosForm({ dados, onSubmit, isLoadingSubmit }) {
  return (
    <Formik
      initialValues={{ ...retirarNulosCargaFormulario(dados) }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      initialTouched={{
        AssEmail: true,
        Telefone: true,
        Celular: true,
        CEPResid: true,
        Residencia: true,
        numeroResidencia: true,
        Bairro: true,
      }}
    >
      {({ values, handleChange, handleSubmit, isValid }) => (
        <form className="form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="form-grupo">
                <label>
                  <b>Nome</b>
                </label>
                <input
                  value={values.Nome}
                  disabled
                  className="form-control"
                  name="Nome"
                  size="30"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-grupo">
                <label>
                  <b>CPF</b>
                </label>
                <InputMask
                  mask="999.999.999-99"
                  disabled
                  className="form-control"
                  value={values.CPF}
                  name="CPF"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-grupo">
                <label>
                  <b>RG</b>
                </label>
                <input
                  size="30"
                  value={values.RG}
                  disabled
                  className="form-control"
                  name="RG"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-grupo">
                <label>
                  <b>E-Mail</b>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <ErrorMessage
                  component="small"
                  className="erro"
                  name="AssEmail"
                />
                <input
                  disabled
                  value={values.AssEmail}
                  className="form-control"
                  name="AssEmail"
                  size="30"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-grupo">
                <label>
                  <b>CEP</b>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <ErrorMessage
                  component="small"
                  className="erro"
                  name="CEPResid"
                />
                <InputMask
                  disabled
                  mask="99999-999"
                  name="CEPResid"
                  className="form-control"
                  value={values.CEPResid}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-grupo">
                <label>
                  <b>Residencia</b>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <ErrorMessage
                  component="small"
                  className="erro"
                  name="Residencia"
                />
                <input
                  disabled
                  value={values.Residencia}
                  className="form-control"
                  name="Residencia"
                  size="30"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-1 col-md-3">
              <div className="form-grupo">
                <label>
                  <b>Número</b>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <ErrorMessage
                  component="small"
                  className="erro"
                  name="numeroResidencia"
                />
                <input
                  disabled
                  value={values.numeroResidencia}
                  className="form-control"
                  name="numeroResidencia"
                  size="30"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-grupo">
                <label>
                  <b>Bairro</b>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <ErrorMessage
                  component="small"
                  className="erro"
                  name="Bairro"
                />
                <input
                  disabled
                  value={values.Bairro}
                  name="Bairro"
                  className="form-control"
                  size="30"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-grupo">
                <b>Celular</b>
                <span style={{ color: "red" }}>*</span>
                <ErrorMessage
                  component="small"
                  className="erro"
                  name="Celular"
                />
                <InputMask
                  disabled
                  mask="(99) 9?99999999"
                  className="form-control"
                  value={values.Celular}
                  name="Celular"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-grupo">
                <b>Telefone</b>
                <span style={{ color: "red" }}>*</span>
                <ErrorMessage
                  component="small"
                  className="erro"
                  name="Telefone"
                />
                <InputMask
                  disabled
                  mask="(99) 9?99999999"
                  className="form-control"
                  value={values.Telefone}
                  name="Telefone"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <br />
        </form>
      )}
    </Formik>
  );
}
