import React, { Component } from 'react';
import Main from '../template/Main';
import { showMessageSuccess, showMessageError, showMessageAlert } from '../others/toastr'
import { Button } from 'primereact/button';
import { api, inscricao } from '../service/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Modal from 'react-bootstrap/Modal';


const initialState = {
    dados: [],
    dependentes: [],
    showModal: false,
    loading: true
}


export default class newDependentes extends Component {

    state = {
        ...initialState
    }

    async componentDidMount() {
        await api.get(`/associados/${inscricao}`)
            .then(resp => {
                this.setState({ dados: resp.data[0] })
            })
            .catch(err => {
                showMessageError("Error", "Erro ao enviar requisição!")
            })

        await api.get(`/dependentes/${inscricao}`)
            .then(resp => {
                this.setState({ dependentes: resp.data, loading: false })
            })
            .catch(err => {
                showMessageError("Error", "Erro ao enviar requisição!")
            })
    }

    handleOpenModal() {
        this.setState({ showModal: true })
    }

    handleClosaModal() {
        this.setState({ showModal: false })
    }

    renderModal() {
        return (
            <Modal show={this.state.showModal} onHide={() => this.handleClosaModal()}>
                <Modal.Header closeButton>
                    <b>Informações Dependentes</b>
                </Modal.Header>
                <Modal.Body closeButton>
                    Para adicionar ou excluir dependentes, gentileza entrar em contato com a central de atendimento
                    Grupo Zelo de segunda a sábado das 07:00 as 20:30h
                    através do número <b>(31)3314-8000</b> ou presencialmente em nossas unidades!

                </Modal.Body>
            </ Modal>
        )
    }

    render() {
        return (
            <Main>
                <div className="container"><h5><b>Dependentes do Plano</b></h5></div>
                <hr />
                <Button label="Adicionar Dependentes" className="p-button-raised p-button-info" onClick={() => this.handleOpenModal()} />
                {this.renderModal()}
                <DataTable value={this.state.dependentes} paginator={true} loading={this.state.loading} rows={20} sortField="data" >
                    <Column field="Nome" header="Nome" sortable={true} />
                    <Column field="Nascimento" header="Nascimento" />
                    <Column field="Parentesco" header="Parentesco" />
                    <Column field="DepSexo" header="Sexo" />
                    <Column field="CPF" header="CPF" />
                    <Column field="Telefone" header="Telefone" />
                    <Column field="Email" header="Email" />
                </DataTable>
            </Main>
        )
    }
}