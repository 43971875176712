import React, { Component, Fragment } from 'react';
import { Button } from "primereact/button";
import Main from '../template/Main'
import backE from '../service/backEnd'
import { InputMask } from "primereact/inputmask";
import { Link } from 'react-router-dom'
import { showMessageError } from '../others/toastr';

export default class quitacaoDebitos extends Component {

    state = {
        pdf: [],
        value1: []
    }

    componentDidMount() {
        backE.post('/pdf')
            .then(resp => {
                this.setState({ pdf: resp.data })
            }).catch(err => {
                showMessageError("Error", "Erro ao enviar requisição!")
            })
    }

    load(ano) {
        this.setState({ ano })
    }

    salvarAno() {
        const anos = this.state.value1
        sessionStorage.setItem('anoQuitacao', anos)
    }

    pesquisaQuitacao() {
        return (
            <Fragment>
                <h6><b>Informe o ano para gerar a Declaração:</b></h6>
                <InputMask mask="9999" type="text" style={{ width: '100px', height: '30px' }}
                    onChange={(e) => this.setState({ value1: e.target.value })} placeholder="Ano" />
                <Link to="/pdfquitacao">
                    <Button label="Gerar" className="p-button-success" type="input" onClick={() => this.salvarAno()} />
                </Link>
            </Fragment>
        )
    }

    render() {
        return (
            <Main>
                <div className='container'><h5><b>Declaração de Quitação de Débitos</b></h5></div>
                <hr />
                {this.pesquisaQuitacao()}
            </Main>
        )
    }
}