import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { api } from "../service/api";
import { login } from "./auth";
import Container from "@material-ui/core/Container";
import { Form, Div } from "./style";
import LogoZelo from "../../assets/imgs/images.jpg";
import TextField from "@material-ui/core/TextField";
import { InputMask } from "primereact/inputmask";
import { enviaLogsAcesso } from "../auth/logAcesso";
import { AssociateContext } from "../../context/AssociateContext";
import { useLocalStorage } from "../../hooks/localStorage";
import ReCAPTCHA from "react-google-recaptcha";
import { encryptSHA256 } from "../../utils/encryptSHA256";
import { getValue } from "../../utils/hash/index";

class SignIn extends Component {
  static contextType = AssociateContext;
  state = {
    inscricao: "",
    CPF: "",
    error: "",
    recaptcha: false,
    recaptchaError: false,
  };

  handleSignIn = async (e) => {
    useLocalStorage().clear();
    e.preventDefault();
    const ipCliente = sessionStorage.getItem("IPCliente");
    const { inscricao, CPF } = this.state;
    sessionStorage.setItem("inscricao", inscricao);
    if (!inscricao || !CPF) {
      this.setState({ error: "Preencha Inscrição e CPF!" });
    } else {
      window.dataLayer.push({
        event: "login",
        user_id: inscricao,
        cpf_usuario: encryptSHA256(CPF),
        numero_inscricao: inscricao,
      });

      await api
        .post("/Associados/authAreaCliente", { inscricao, CPF })
        .then(async (result) => {
          login(result.data.token);
          await enviaLogsAcesso(inscricao, ipCliente);

          const { fetchAssociado } = this.context;

          await fetchAssociado();

          this.props.history.push("/");
          window.location.reload();
        })
        .catch((err) => {
          this.setState({
            error: "Inscrição ou CPF Invalidos",
          });
        });
    }
  };

  render() {
    return (
      <Container component="main" maxWidth="xs" style={{ margin: "50px" }}>
        <Div>
          <img
            src={LogoZelo}
            style={{ width: "175px", height: "170px", marginLeft: "100px" }}
          />
          <Form onSubmit={this.handleSignIn}>
            {this.state.error && <p>{this.state.error}</p>}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={(e) => this.setState({ inscricao: e.target.value })}
              label="Numero da Inscrição"
              autoFocus
            />
            <InputMask
              mask="99999999999"
              value={this.state.CPF}
              onChange={(e) => this.setState({ CPF: e.target.value })}
              style={{ width: "100%", height: "50px", margin: "5px" }}
              placeholder="CPF"
            />
            <div>
              <div id="captcha">
                <ReCAPTCHA
                  sitekey={getValue(process.env.REACT_APP_14)}
                  onChange={(captchaEvent) => {
                    this.setState({
                      recaptcha: captchaEvent,
                      recaptchaError: false,
                    });
                  }}
                />
              </div>
            </div>
            <Button
              disabled={!this.state.recaptcha}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Entrar
            </Button>
          </Form>
        </Div>
      </Container>
    );
  }
}

export default withRouter(SignIn);
