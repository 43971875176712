import React, { Component } from 'react'
import Main from '../template/Main'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import Modal from 'react-bootstrap/Modal'
import { ListBox } from 'primereact/listbox';
import { showMessageSuccess, showMessageAlert, showMessageError } from '../others/toastr'
import { api, inscricao } from '../service/api'
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Link } from 'react-router-dom'
import backE from '../service/backEnd';


const options = [
    { label: 'CARTÃO CREDITO RECORRENTE', value: '7' }
];

export default class AlterarPagamento extends Component {

    state = {
        dados: null,
        spinner: true,
        showForm: '',
    }

    componentDidMount() {
        api.get(`/associados/${inscricao}`)
            .then(resp => {

                this.setState({ dados: resp.data[0], spinner: false })
            }).catch(err => {
                showMessageError("Error", "Erro ao enviar requisição!")
            })
    }

    save() {
        const name = this.state.dados.Nome
        const email = this.state.dados.AssEmail
        const inscricao = this.state.dados.Inscricao
        const telefone = this.state.dados.Telefone

        // const save = { AssTipoCobranca: 7}
        // delete save.Cidad5
        // delete save.Inscricao
        // delete save.status
        // delete save.SubGrupo
        // delete save.DataUpdate
        if (telefone === null || telefone === undefined) {
            showMessageError("Error", "Telefone Invalido! Atualize deus dados para continuar.")
            return
        }
        if (email === null || email === undefined) {
            showMessageError("Error", "Email Invalido! Atualize deus dados para continuar.")
            return
        }
        backE.post(`/ativarRecorrencia`, {
            "email": email,
            "name": name,
            "inscricao": inscricao,
            "telefone": telefone
        })
            .then(resp => {
                this.setState({ save: resp.data })
                showMessageSuccess("Sucesso", "Requisição enviada com sucesso!")
            }).catch(err => {
                showMessageError("Error", "Erro ao enviar requisição!")
            })
    }

    handleCloseModalForm() {
        this.setState({ showForm: false })
    }

    modalForm() {
        return (
            <>
                <Modal show={this.state.showForm} onHide={() => this.handleCloseModalForm()}>
                    <Modal.Header closeButton>
                        <Modal.Title><h5>Janela de Informações</h5>
                            <div><h6>Confira seus dados antes de enviar!</h6></div></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form">
                            <div className="container"></div>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="form-grupo">
                                        <label><b>Nome</b></label>
                                        <InputText type="text" readOnly className="form-control" name="nometitular" value={this.state.dados.Nome} />

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label><b>E-Mail</b></label>
                                        <input type="text" className="form-control" name="email" readOnly value={this.state.dados.AssEmail} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-grupo">
                                        <label><b>Telefone</b></label>
                                        <input type="text" className="form-control" name="Telefone" readOnly value={this.state.dados.Telefone} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <Button label="Salvar" style={{width:'100px'}} className="p-button-rounded p-button-success" onClick={e => this.save(e)} /> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button label="Enviar" onClick={e => this.save(e)} className="p-button-rounded p-button-success" />
                        <Link to="/setings"><Button label="Alterar Dados" className="p-button-rounded p-button-info" /></Link>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    async handleOpenModalForm() {
        // await api.post('/payzen/validateCard',
        //     {
        //         "orderId": "myOrderId-6634004", //match.random
        //         "customer": {
        //             "email": "sample@example.com", //email cliente
        //             "reference": "88032223" //inscriçao cliente
        //         }
        //     })
        //     .then(resp => {
        //         const recorrent = resp.data.formToken
        //         localStorage.setItem('recorrentpay', recorrent)
        //     }).catch(err => {

        //     })

        this.setState({ showForm: true })
    }

    renderTable() {
        return (
            <>
                <label><h5>Ative a Recorrencia no seu pagamento</h5></label>
                <div>Clique no botão abaixo para receber mais informações de como ativar a recorrencia no cartão de credito!</div>
                <br />
                <div className="col-12 col-md-3">
                    {/* <ListBox value={this.state.dados} options={options}
                        onChange={(e) => this.setState({ dados: e.target.value.value })} optionLabel="label" optionValue="value" /> */}
                </div>
                <Button label="Ativar Recorrencia" className=" p-button p-button-info" onClick={e => this.handleOpenModalForm()} />
                {this.modalForm()}

            </>
        )
    }

    render() {
        return (
            <Main>
                <div>
                    <div className='container'><h5><b>Atualizar Forma de Pagamento</b></h5></div>
                    {this.state.spinner ? <ProgressSpinner style={{
                        width: '50px', height: '50px', marginLeft:
                            '50%'
                    }} /> : this.renderTable()}
                </div>
                <br />
            </Main>
        )
    }
}