import { showMessageError, showMessageSuccess } from "../../others/toastr";
import { isObjetosIguais } from "../../../utils/validaObjeto";
import {
  gravarDadosAssociadoVendas,
  gravarDadosTouchpoint,
  gravarLogHistorico,
} from "../../service/associado";
import moment from "moment";
import { getValue } from "../../../utils/hash/index";

const STATUS_LOG = {
  UPDATED: "ATUALIZADO",
  CONFIRMED: "CONFIRMADO",
};

export function preparadaAtualizacaoCadastral(formValues, associadoContext) {
  const branchUser = getValue(process.env.REACT_APP_11);
  const user = getValue(process.env.REACT_APP_12);
  let status = STATUS_LOG.CONFIRMED;

  const dadosAtualizaApiVendas = {
    CEPResid: formValues.CEPResid?.replace(/ |-|\(|\)/gi, ""),
    Residencia: formValues.Residencia,
    AssEmail: formValues.AssEmail,
    numeroResidencia: formValues.numeroResidencia,
    Bairro: formValues.Bairro,
    Telefone: formValues.Telefone?.replace(/ |-|\(|\)/gi, ""),
    Celular: formValues.Celular?.replace(/ |-|\(|\)/gi, ""),
    DataUpdate: moment().subtract(3, "hours"),
  };

  const dadosTouchpoint = {
    status,
    user,
    branch: {
      id: branchUser,
      name: "AREA DO CLIENTE",
      state: "MG",
    },
    subscription: associadoContext.Inscricao,
    before: {
      name: associadoContext.Nome,
      cpf: associadoContext.CPF?.replace(/ |-|\(|\)/gi, ""),
      rg: associadoContext.RG?.replace(/ |-|\(|\)/gi, ""),
      email: associadoContext.AssEmail,
      gender: associadoContext.Sexo,
      phone1: associadoContext.Telefone?.replace(/ |-|\(|\)/gi, ""),
      phone2: associadoContext.Telefone2?.replace(/ |-|\(|\)/gi, ""),
      mobile: associadoContext.Celular?.replace(/ |-|\(|\)/gi, ""),
      address: {
        street: associadoContext.Residencia,
        number: associadoContext.numeroResidencia,
        complement: associadoContext.Complemento,
        neighborhood: associadoContext.Bairro,
        postalCode: associadoContext.CEPResid?.replace(/ |-|\(|\)/gi, ""),
        city: associadoContext.Cidade,
        state: associadoContext.UF,
      },
      billingAddress: {
        street: associadoContext.Cobranca,
        number: associadoContext.numeroCobranca,
        complement: associadoContext.ComplementoCob,
        neighborhood: associadoContext.BairroCob,
        postalCode: associadoContext.CepCob,
        city: associadoContext.CidadeCob,
        state: associadoContext.UF,
      },
    },
    after: {
      name: formValues.Nome,
      cpf: formValues.CPF?.replace(/ |-|\(|\)/gi, ""),
      rg: formValues.RG?.replace(/ |-|\(|\)/gi, ""),
      email: formValues.AssEmail,
      gender: associadoContext.Sexo,
      phone1: formValues.Telefone?.replace(/ |-|\(|\)/gi, ""),
      phone2: associadoContext.Telefone2?.replace(/ |-|\(|\)/gi, ""),
      mobile: formValues.Celular?.replace(/ |-|\(|\)/gi, ""),
      address: {
        street: formValues.Residencia,
        number: formValues.numeroResidencia,
        complement: associadoContext.Complemento,
        neighborhood: formValues.Bairro,
        postalCode: formValues.CEPResid?.replace(/ |-|\(|\)/gi, ""),
        city: associadoContext.Cidade,
        state: associadoContext.UF,
      },
      billingAddress: {
        street: associadoContext.Cobranca,
        number: associadoContext.numeroCobranca,
        complement: associadoContext.ComplementoCob,
        neighborhood: associadoContext.BairroCob,
        postalCode: associadoContext.CepCob,
        city: associadoContext.CidadeCob,
        state: associadoContext.UF,
      },
    },
  };

  const dadosLogAssociado = {
    branchUser: [branchUser],
    status,
    subscriptionCode: associadoContext.Inscricao,
    updatedCustomerAddress: !isObjetosIguais(
      dadosTouchpoint.after?.address,
      dadosTouchpoint.before?.address
    ),
    updatedCustomerBillingAddress: !isObjetosIguais(
      dadosTouchpoint.after?.billingAddress,
      dadosTouchpoint.before?.billingAddress
    ),
    updatedCustomerName:
      dadosTouchpoint.before?.name !== dadosTouchpoint.after?.name ?? false,
    updatedCustomerCpf:
      dadosTouchpoint.before?.cpf !== dadosTouchpoint.after?.cpf ?? false,
    updatedCustomerEmail:
      dadosTouchpoint.before?.email !== dadosTouchpoint.after?.email ?? false,
    updatedCustomerCellPhone:
      dadosTouchpoint.before?.mobile !== dadosTouchpoint.after?.mobile ?? false,
    updatedCustomerFirstPhone:
      dadosTouchpoint.before?.phone1 !== dadosTouchpoint.after?.phone1 ?? false,
    updatedCustomerSecondPhone:
      dadosTouchpoint.before?.phone2 !== dadosTouchpoint.after?.phone2 ?? false,
    userId: user,
  };

  const cadastroAlterado = Object.values(dadosLogAssociado)
    .filter((valor) => typeof valor === "boolean")
    .includes(true);

  if (cadastroAlterado) {
    dadosLogAssociado.status = STATUS_LOG.UPDATED;
    dadosTouchpoint.status = STATUS_LOG.UPDATED;
  }
  return { dadosAtualizaApiVendas, dadosTouchpoint, dadosLogAssociado };
}

export async function atualizacaoCadastral({
  inscricao,
  dadosAtualizaApiVendas,
  dadosTouchpoint,
  dadosLogAssociado,
}) {
  try {
    await Promise.all([
      gravarAssociadoVendas(inscricao, dadosAtualizaApiVendas),
      gravarTouchpoint(dadosTouchpoint),
      // gravarLogAssociado(dadosLogAssociado),
    ]);
    showMessageSuccess("Sucesso", "Dados atualizados com sucesso!");
  } catch (error) {
    showMessageError(
      "Erro ao salvar associado.",
      "Tente novamente mais tarde."
    );
  }
}

export async function gravarAssociadoVendas(inscricao, dadosAtualizaApiVendas) {
  return await gravarDadosAssociadoVendas(inscricao, dadosAtualizaApiVendas);
}

export async function gravarTouchpoint(dadosTouchpoint) {
  return await gravarDadosTouchpoint(dadosTouchpoint);
}

export async function gravarLogAssociado(dadosTouchpoint) {
  await gravarLogHistorico(dadosTouchpoint);
}
