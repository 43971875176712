import React, { Component, Fragment } from 'react';
import Main from '../template/Main'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { api } from '../service/api'


export default class extratoBoletos extends Component {

    state = {
        dados: {},
        loading: true
    }

    componentDidMount() {
        api.get('/mensalidades/pagas/21500')
            .then(resp => {
                this.setState({
                    dados: resp.data,
                    loading: false
                })
            }).catch(err => {
                this.setState({ spinner: true })
            })
    }

    onPage(event) {
        this.setState({
            loading: true
        });
        //imitate delay of a backend call
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + this.state.rows;

            this.setState({
                first: startIndex,
                cars: this.datasource.slice(startIndex, endIndex),
                loading: false
            });
        }, 250);
    }

    pesquisaQuitacao() {
        return (
            <Fragment>
                <div className="container">Pesquisa na Tabela</div>
                <InputText type="search" style={{ marginLeft: '320px' }} loading={this.state.loading} onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Digite Aqui" size="50" />
                <br />
                <br />
                <tr>
                    <th> Boletos</th>
                </tr>
                <DataTable autoLayout={true} style={{ textAlign: 'center' }} globalFilter={this.state.globalFilter}
                    value={this.state.dados} paginator={true} rows={20} sortField="data" loading={this.state.loading} sortOrder={1}>
                    <Column field="Referencia" header="Referencia" sortable={false} />
                    <Column field="Valor" header="Valor Pagamento" sortable={true} />
                    <Column field="Vencimento" header="Vencimento" sortable={false} />
                    <Column field="Pagamento" header="Pagamento" sortable={false} />
                </DataTable>
            </Fragment>
        )
    }

    render() {
        return (
            <Main>
                <div className='container'><h5><b>Extrato de Boletos Pagos</b></h5></div>
                <hr />
                {this.pesquisaQuitacao()}

            </Main>
        )
    }
}