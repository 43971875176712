import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  height: auto;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100px;
    margin: 10px 0 40px;
  }
  p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 1px solid #ff3333;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  button {
    font-size: 16px;
  }
  #captcha {
    margin: 20px 0px 20px 0px;
  }
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }
`;

export const Div = styled.div`
width: 450px;
    padding: 19px;
    marginTop: 30px;
    left: 32%;
    display: flex
    flexDirection: column;
    alignItems: center;
    position: absolute;
`;