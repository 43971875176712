export const TOKEN_KEY = "LG4335@!5mvzkvKKsgnk%56464kofMGOAMG56136GOMSAG_aKOG";
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const login = (token) => {
    sessionStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
    window.location.reload();
    localStorage.clear();
    sessionStorage.clear();
};