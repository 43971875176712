import React from 'react'
import { Button } from 'primereact/button';
export default function BoletoNaoEncontrado({labelBotaoReegerar='Tente novamente', mensagemSucessoOuErro = 'Não foi possível localizar o boleto solicitado, por favor tente novamente clicando no botão abaixo, caso o problema persista, por gentileza entre em contato com a nossa central de atendimento.'
  , erro = false }) {
  return (
    <>
      <div style={{backgroundColor: erro ? '#ffc107':'#4BB543'}} className='mensagem-boleto'>
        <h4>{mensagemSucessoOuErro}</h4>
      </div>

      <Button style={{width: '375px',fontSize:'25px', marginBottom: '1rem'} } label={labelBotaoReegerar} icon='pi pi-replay' onClick={() => window.location.reload()} />
    </>
  )
}
