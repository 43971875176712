import React, { useState } from "react";
import {
  atualizacaoCadastral,
  preparadaAtualizacaoCadastral,
} from "./infraestrutura/atualizacaoCadastral";
import ModalAtualizacaoCadastral from "./atom/ModalAtualizacaoCadastral";
import { useAssociateContext } from "../../context/AssociateContext";
import { diferencaEmDias } from "../../utils/datas";
import { isObjetoVazio } from "../../utils/validaObjeto";
import { useLayoutEffect } from "react";
import FeedbackErro from "./atom/FeedbackErro";

const AtualizacaoCadastral = () => {
  const [show, setShow] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const {
    associadoContext,
    buscandoAssociado,
    historicoAssociado,
    touchpointAssociado,
    errosRequisicao,
    buscouAssociado,
  } = useAssociateContext();

  useLayoutEffect(() => {
    if (buscouAssociado) {
      const dataAtual = new Date().toISOString();

      const constaErro = Object.values(errosRequisicao)
        .filter((valor) => typeof valor === "boolean")
        .includes(true);

      if (
        !constaErro &&
        (isObjetoVazio(touchpointAssociado) ||
          touchpointAssociado === undefined ||
          diferencaEmDias(dataAtual, associadoContext.DataUpdate) >= 60)
        // (isObjetoVazio(historicoAssociado) || historicoAssociado === undefined) ||
        // (!isObjetoVazio(historicoAssociado) && diferencaEmDias(dataAtual, historicoAssociado[0]?.updatedAt) >= 60))
      ) {
        setShow(true);
      }
    }
  }, [buscouAssociado]);

  const onSubmit = async (formValues) => {
    setIsLoadingSubmit(true);

    const { dadosAtualizaApiVendas, dadosTouchpoint, dadosLogAssociado } =
      preparadaAtualizacaoCadastral(formValues, associadoContext);

    await atualizacaoCadastral({
      inscricao: associadoContext.Inscricao,
      dadosAtualizaApiVendas,
      dadosTouchpoint,
      dadosLogAssociado,
    });
    setShow(false);
    setIsLoadingSubmit(false);
  };

  return (
    <>
      <FeedbackErro
        errosRequisicao={errosRequisicao?.associado}
        tituloErro={"Tivemos problemas ao buscar dados do Associado."}
        mensagemErro={"Tente novamente mais tarde."}
      >
        {associadoContext && (
          <ModalAtualizacaoCadastral
            show={show}
            setShow={setShow}
            dados={associadoContext}
            onSubmit={onSubmit}
            isLoadingSubmit={isLoadingSubmit}
            buscandoAssociado={buscandoAssociado}
          />
        )}
      </FeedbackErro>
    </>
  );
};

export default AtualizacaoCadastral;
