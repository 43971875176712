import Axios from "axios";
import { apiPix } from "./api";
import { getValue } from "../../utils/hash";

const areaDoClienteUrl = getValue(process.env.REACT_APP_9);

export const buscaAssociado = async (inscricao) => {
  try {
    const associado = await apiPix.get(`/associados/${inscricao}`);

    return associado.data;
  } catch (error) {
    return error;
  }
};

export const buscaHistoricoUpdateAssociado = async (inscricao) => {
  try {
    const historicoUpdateAssociado = await Axios.get(
      `${areaDoClienteUrl}/proxy/associate-manager/history/by-subscription/sync?subscriptionCode=${inscricao}`
    );
    return historicoUpdateAssociado.data;
  } catch (error) {
    return error;
  }
};

export const buscaTouchpointAssociado = async (inscricao) => {
  try {
    const touchpointAssociado = await Axios.get(
      `${areaDoClienteUrl}/proxy/zelo-touchpoint/associates/subscription/${inscricao}`
    );

    return touchpointAssociado.data;
  } catch (error) {
    return error;
  }
};

export const gravarDadosAssociadoVendas = async (inscricao, dadosAssociado) => {
  try {
    return await apiPix.put(
      `/associados/area-cliente/${inscricao}`,
      dadosAssociado
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const gravarDadosTouchpoint = async (dadosTouchpoint) => {
  try {
    const touchpointAssociado = await Axios.post(
      `${areaDoClienteUrl}/proxy/zelo-touchpoint/transactions`,
      dadosTouchpoint
    );

    return touchpointAssociado.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const gravarLogHistorico = async (dadosHistorico) => {
  try {
    const historicoAssociado = await Axios.post(
      `${areaDoClienteUrl}/proxy/associate-manager/history/create-history`,
      dadosHistorico
    );

    return historicoAssociado.data;
  } catch (error) {
    throw new Error(error);
  }
};
