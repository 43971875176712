import React, { Component } from 'react';
import Main from '../template/Main';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'
import Modal from 'react-bootstrap/Modal'
import { ProgressSpinner } from 'primereact/progressspinner'
import { api, apiPix, inscricao } from '../service/api'
import { Checkbox } from 'primereact/checkbox';
import './dataTable.css';
import './DataTable.scss';
import * as moment from 'moment';
import ModalBoleto from './ModalBoleto';

import AdyenPopUp from './AdyenPopUp';

import { Pix } from '../pix';
import { gravarLogGenerico } from '../service/gravarLog';
import BoletoButton from './BoletoButton';
import { showMessageError } from '../others/toastr';


let titululinha;
let elegivelBoleto;
export default class Boleto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teste: [],
            show: false,
            showModal: false,
            abrirBoleto: false,
            pixModal: false,
            mensalidade: [],
            abertas: [],
            pagamento: [],
            checked: false,
            loading: true,
            selectedBoletos: [],
            dados: [],
            AssTipoCobranca: '',
            status: 0,
            restrictedPayment: [],
            AssociadoAdyen: {},
            linhaSelecionada: {}
        }
        this.rowGrafite = this.rowGrafite.bind(this);
    }

    async handleClickGravaLog(tipoPagamento) {
        try {
            const dadosAssociado = this.state.dados[0]
            const dadosLogPagamento = {
                inscricao: dadosAssociado.Inscricao,
                tipoPagamento,
                parcela: this.state.selectedBoletos.Referencia,
                dataHora: moment().format("YYYY-MM-DD HH:mm:ss"),
                ip: sessionStorage.getItem("IPCliente"),
            }

            await gravarLogGenerico('/log-area-cliente/log-pagamento', dadosLogPagamento)
        } catch (error) {
            throw new Error(error.message)
        }
    }

    handleClose() {
        window.location.reload();
        this.setState({ show: false })
    }

    async handleOpen() {
        try {
            await this.handleClickGravaLog('Cartão')
            this.setState({
                show: true,
                AssociadoAdyen: {
                    Inscricao: this.state.dados[0]?.Inscricao,
                    SubGrupo: this.state.dados[0].SubGrupo,
                    Valor: (Number(this.state.selectedBoletos.Valor) * 100).toFixed(0),
                    Referencia: this.state.selectedBoletos.Referencia,
                    TipoAceite: 2
                }
            })
        } catch (error) {
            showMessageError('Algo deu errado, tente novamente mais tarde!')
        }

    }

    async handlePixOpen() {
        try {
            await this.handleClickGravaLog('Pix')
            this.setState({ pixModal: true })
        } catch (error) {
            showMessageError('Algo deu errado, tente novamente mais tarde!')
        }

    }

    handlePixClose() {
        this.setState({ pixModal: false })
    }

    handleModalClose() {
        this.setState({ showModal: false, show: false })
    }

    handleModalOpen() {
        this.setState({ showModal: true });
    };

    async componentWillMount() {
        localStorage.clear();
        await api.get(`/associados/${inscricao}`)
            .then(result => {
                this.setState({ dados: result.data })
                this.setState({ AssTipoCobranca: result.data[0].AssTipoCobranca })
                this.setState({ status: result.data[0].Status })
            }).catch(err => {
                showMessageError("Error", "Erro ao enviar requisição!")
            })

        await apiPix.get(`/mensalidades/todas/${inscricao}`)
            .then(resp => {
                this.setState({ mensalidade: resp.data, loading: false })
            }).catch(err => {
                this.setState({ loading: false })
                return true
            })
        await apiPix.get(`/mensalidades/abertas/${inscricao}`)
            .then(resp => this.setState({ abertas: resp.data }))
            .catch(err => showMessageError("Error", "Erro ao enviar requisição!"))

        await apiPix.get(`/pagamentos/consulta-bloqueios-tipo-pagamento/${inscricao}`)
            .then(response => {
                const allPaymentTypes = [];
                if (!!response.data.length) {
                    response.data.forEach((data) => {
                        allPaymentTypes.push(+data['RegrasTipoPagamentoClientes.TipoPagamento']);
                    });
                }

                this.setState({ restrictedPayment: allPaymentTypes })
            }).catch(error => {
                showMessageError("Error", "Erro ao enviar requisição!")
            });
    };
    onPage(event) {
        this.setState({
            loading: true
        });
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + this.state.rows;

            this.setState({
                first: startIndex,
                cars: this.datasource.slice(startIndex, endIndex),
                loading: false
            });
        }, 250);
    }

    rowGrafite(data) {
        if (this.state.checked === false)
            var atual = moment().format('YYYYMM')
        var ref = moment(data.Referencia, ['MM/YYYY']).format('YYYYMM')

        if (ref < atual) {
            return {
                'row-accessories': data.Referencia
            }
        } else {
            return
        }
    }

    layoutsPayment(lineTitle, origemBoleto, numBoleto) {
        let selectedPaymentOverdue = false;
        if (this.state.selectedBoletos.Vencimento !== undefined) {
            const [day, month, year] = this.state.selectedBoletos.Vencimento.split('/');
            const currentDate = new Date();
            const dueDate = new Date(`${year}-${month}-${day}`);

            if (currentDate > dueDate) selectedPaymentOverdue = true;
        }

        const dadosAssociado = this.state.dados[0]
        const dadosLogPagamento = {

            inscricao: dadosAssociado.Inscricao,
            tipoPagamento: 'Boleto',
            parcela: this.state.selectedBoletos.Referencia,
            dataHora: moment().format("YYYY-MM-DD HH:mm:ss"),
            ip: sessionStorage.getItem("IPCliente"),
            elegivelBoleto
        }


        const allLayouts = [];
        let containPaymentLayout = false;

        if (
            (this.state.restrictedPayment.includes(1) && selectedPaymentOverdue) ||
            (lineTitle === null && numBoleto === null)
        )
            allLayouts.push(<span></span>);
        else {
            allLayouts.push(<BoletoButton origemBoleto={origemBoleto} dadosLogPagamento={dadosLogPagamento} handleBoleto={this.handleBoleto} />);
            containPaymentLayout = true;
        }


        if ((this.state.restrictedPayment.includes(2) && selectedPaymentOverdue))
            allLayouts.push(<div></div>);
        else {
            allLayouts.push(<Button label="Cartão" className="p-button-raised p-button-info" onClick={() => this.handleOpen()} />);
            containPaymentLayout = true;
        }

        if ((this.state.restrictedPayment.includes(3)) && selectedPaymentOverdue)
            allLayouts.push(<div></div>);
        else {
            allLayouts.push(<Button label="PIX" className="p-button-raised p-button-info" onClick={() => this.handlePixOpen()} />);
            containPaymentLayout = true;
        }

        if (!containPaymentLayout)
            allLayouts.push(<div>Nenhuma forma da pagamento disponível.</div>);

        return allLayouts;
    }

    bodyValorParcela(rowData) {
        return rowData.Valor ? new Intl.NumberFormat(
            'pt-BR',
            {
                style: 'currency',
                currency:
                    'BRL'
            }
        ).format(
            rowData.Valor.replace(',', '.')
        ) : new Intl.NumberFormat(
            'pt-BR',
            {
                style: 'currency',
                currency:
                    'BRL'
            }
        ).format(
            '0'
        );
    }

    boletosAberto() {
        titululinha = this.state.linhaSelecionada.TituloLinhaDigitavel
        const origemBoleto = this.state.linhaSelecionada.Origem
        const numBoleto = this.state.linhaSelecionada.NumBoleto
        elegivelBoleto = titululinha != null ? 'T' : 'F'

        const layoutPayment = this.layoutsPayment(titululinha, origemBoleto, numBoleto);

        const header = (
            <div style={{ 'textAlign': 'left' }}>
                Exibir Pagos <Checkbox checked={this.state.checked} onChange={e => this.setState({ checked: e.checked })} />
            </div>
        );

        return (
            <div className="datatable-style">
                <div className="col-md-12">
                    {(this.state.status !== 2) ?
                        <Button label="Pagar Selecionado" className="p-button-raised p-button-info" style={{ marginBottom: '8px' }} onClick={() => this.handleModalOpen()} />
                        : <ModalBoleto />

                    }
                    {this.state.selectedBoletos !== 0 &&
                        <Modal show={this.state.showModal} onHide={() => this.handleModalClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title><h3>Forma de Pagamento</h3></Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                {!this.state.show && <div className="col-md-12 optionsPayment">
                                    {layoutPayment}
                                </div>}
                                {this.state.show && <AdyenPopUp Associado={this.state.AssociadoAdyen} ReturnUrl={'http://localhost:3000'} />}
                            </Modal.Body>
                        </Modal>
                    }
                    <Modal show={this.state.pixModal} onHide={() => this.handlePixClose()}>
                        <Modal.Header closeButton>
                            <Modal.Title><h3>Pagamento Pix</h3></Modal.Title>
                        </Modal.Header>

                        <Modal.Body style={{ display: 'contents' }}>
                            <Pix
                                dadosParcelas={this.state.selectedBoletos}
                            />
                            <Button label="Voltar" className="p-button-raised p-button-info" onClick={() => this.handlePixClose()} />

                        </Modal.Body>
                    </Modal>
                    <Modal show={this.state.pixModal} onHide={() => this.handlePixClose()}>
                        <Modal.Header closeButton>
                            <Modal.Title><h3>Pagamento Pix</h3></Modal.Title>
                        </Modal.Header>

                        <Modal.Body style={{ display: 'contents' }}>
                            <Pix
                                dadosParcelas={this.state.selectedBoletos}
                            />
                            <Button label="Voltar" className="p-button-raised p-button-info" onClick={() => this.handlePixClose()} />

                        </Modal.Body>
                    </Modal>
                    <br />
                    <DataTable onRowSelect={({ data }) => this.setState({ linhaSelecionada: data })} autoLayout={true} value={this.state.checked ? this.state.mensalidade : this.state.abertas}
                        selection={this.state.selectedBoletos} header={header}
                        onSelectionChange={(e) => this.setState({ selectedBoletos: e.value })}
                        paginator={true} rows={20} sortField="data" loading={this.state.loading}
                        sortOrder={1} rowClassName={this.rowGrafite}>
                        {this.state.checked ? null : <Column selectionMode="single" />}
                        <Column field="Pagamento" header="Pagamento" sortable={true} />
                        <Column field="Referencia" header="Referencia" sortable={true} />
                        <Column field="Vencimento" header="Vencimento" sortable={true} />
                        <Column field="Valor" body={this.bodyValorParcela} header="Valor" sortable={true} />
                    </DataTable>
                </div>
            </div >
        )
    }

    renderTable() {
        const codebar = this.state.selectedBoletos
        sessionStorage.setItem('codigobarra', JSON.stringify(codebar))
        return (
            <div className="content-section implementation">
                {this.state.loading ? <ProgressSpinner style={{
                    width: '50px', height: '50px',
                    marginLeft: '50%'
                }} /> : this.boletosAberto()}
            </div>
        )
    }

    render() {

        return (
            <Main>
                <div className="container"><h5><b>Parcelas</b></h5></div>
                <hr />
                {this.renderTable()}
            </Main>
        )
    }
}
