import React from "react";
import Main from "../template/Main";
import "./home.css";
import { Button } from "primereact/button";
import AtualizacaoCadastral from "../atualizacaoCadastral";
import { Link } from "react-router-dom";

export default (props) => {
  return (
    <Main>
      <div>
        <h5>Área do Cliente</h5>
      </div>
      <hr />
      <div>
        Seja Bem Vindo(a) à área dos associados. Selecione uma opção no menu ao
        lado.
        <hr />
        <p />
        <p />
        <p />
      </div>
    </Main>
  );
};
