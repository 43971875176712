import React, { Component } from 'react';
import backE from '../service/backEnd'
import { api, inscricao } from '../service/api'
import { showMessageSuccess, showMessageError, showMessageAlert } from '../others/toastr'

class MyApp extends Component {
  state = {
    numPages: null,
    pdf: [],
    dados: [],
    pagas: {},
    boleto: { Referencia: '', Vencimento: '' }
  }

  async componentDidMount() {
    showMessageAlert("Carregando", `Carregando declaração... `)
    await api.get(`/associados/${inscricao}`)
      .then(resp => {
        this.setState({ dados: resp.data[0] })
      }).catch(err => {
        showMessageError("Error", "Erro ao enviar requisição!")
      })
    await api.get(`/mensalidades/pagas/${inscricao}`)
      .then(resp => {
        this.setState({ pagas: resp.data })
      }).catch(err => {
        showMessageError("Error", "Erro ao enviar requisição!")
      })

    if (this.state.pagas === "") {
      showMessageError("Erro", `Você não tem Parecelas Pagas! `)
    } else {

      const anos = sessionStorage.getItem('anoQuitacao')
      const referencia = this.state.pagas
      const pagasAno = referencia.filter(mensalidade => {
        if (mensalidade.Vencimento.substr(6, 4) === anos)
          return mensalidade;
      })

      const nome = this.state.dados.Nome
      backE.post('/pdf', {
        "nome": nome,
        "inscricao": inscricao,
        "referencia": anos,
        "mensalidade": {
          pagasAno
        }
      })
        .then(resp => {
          this.setState({ pdf: resp.data })
        }).catch(err => {
          showMessageError("Error", "Erro ao enviar requisição!")
        })
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  render() {
    const html = { __html: this.state.pdf };
    return (
      <>
        <div className="Boleto" style={{ width: '100%' }} >
          <div className="imprimir" dangerouslySetInnerHTML={html} />
        </div>
      </>
    );
  }
}

export default MyApp