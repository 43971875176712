/* eslint-disable */
import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import {
  buscaAssociado,
  buscaHistoricoUpdateAssociado,
  buscaTouchpointAssociado,
} from "../component/service/associado";
import { useLocalStorage } from "../hooks/localStorage";
import { isArray } from "lodash";

const AssociateContext = createContext();

const useAssociateContext = () => {
  return useContext(AssociateContext);
};

const AssociateContextProvider = ({ children }) => {
  const inscricao = sessionStorage.getItem("inscricao");
  const [associadoContext, setAssociadoContext] = useState({});
  const [historicoAssociado, setHistoricoAssociado] = useState({});
  const [touchpointAssociado, setTouchpointAssociado] = useState();
  const [buscandoAssociado, setBuscandoAssociado] = useState(false);
  const [buscouAssociado, setBuscouAssociado] = useState(false);
  const [errosRequisicao, setErrosRequisicao] = useState({
    associado: false,
    historico: false,
    touchpoint: false,
  });

  const fetchAssociado = async () => {
    const associadoSession = useLocalStorage().getItem("associado");
    if (
      !associadoContext &&
      associadoSession &&
      associadoSession.Inscricao == inscricao
    ) {
      updateAssociado(associadoSession);
    } else {
      if (inscricao) {
        setBuscandoAssociado(true);
        const [associado, historico, touchpoint] = await Promise.all([
          buscaAssociado(inscricao),
          // buscaHistoricoUpdateAssociado(inscricao),
          [],
          buscaTouchpointAssociado(inscricao),
        ]);

        if (associado.isAxiosError) {
          setErrosRequisicao((prevError) => ({
            ...prevError,
            ...{ associado: true },
          }));
        } else {
          updateAssociado(associado[0]);
          useLocalStorage().setItem("associado", associado[0]);
        }

        const isHistoricalRequestError =
          historico.isAxiosError &&
          !(
            historico?.response?.data?.statusCode === 404 &&
            historico?.response?.data?.message?.startsWith(
              "History not found for customer"
            )
          );

        if (isHistoricalRequestError) {
          setErrosRequisicao((prevError) => ({
            ...prevError,
            ...{ historico: true },
          }));
        }

        if (!isHistoricalRequestError && isArray(historico)) {
          updateHistoricoAssociado(historico);
          useLocalStorage().setItem("historico", historico);
        }

        const isTouchpointRequestError =
          touchpoint.isAxiosError &&
          !(
            touchpoint?.response?.data?.statusCode === 404 &&
            touchpoint?.response?.data?.message?.startsWith(
              "Associate not found"
            )
          );

        if (isTouchpointRequestError) {
          setErrosRequisicao((prevError) => ({
            ...prevError,
            ...{ touchpoint: true },
          }));
        }

        if (!isTouchpointRequestError) {
          updateTouchpointAssociado(touchpoint);
          useLocalStorage().setItem("touchpoint", touchpoint);
        }

        setBuscandoAssociado(false);
        setBuscouAssociado(true);
      }
    }
  };

  useLayoutEffect(() => {
    if (inscricao) {
      fetchAssociado();
    }
  }, [inscricao]);

  const updateAssociado = (associado) =>
    setAssociadoContext((prevAssociado) => ({
      ...prevAssociado,
      ...associado,
    }));
  const updateTouchpointAssociado = (touchpoint) =>
    setTouchpointAssociado((prevTouchpoint) => ({
      ...prevTouchpoint,
      ...touchpoint,
    }));
  const updateHistoricoAssociado = (historico) =>
    setHistoricoAssociado((prevHistorico) => ({
      ...prevHistorico,
      ...historico,
    }));

  return (
    <AssociateContext.Provider
      value={{
        touchpointAssociado,
        associadoContext,
        updateAssociado,
        fetchAssociado,
        buscandoAssociado,
        historicoAssociado,
        updateHistoricoAssociado,
        updateTouchpointAssociado,
        errosRequisicao,
        buscouAssociado,
      }}
    >
      {children}
    </AssociateContext.Provider>
  );
};

export { AssociateContextProvider, AssociateContext, useAssociateContext };
