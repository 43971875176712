import React, { Component, Fragment } from 'react'
import Main from '../template/Main'
import './segundavia.css';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ListBox } from 'primereact/listbox'
import { ProgressSpinner } from 'primereact/progressspinner'
import { showMessageSuccess, showMessageError, showMessageAlert } from '../others/toastr'
import { api, inscricao } from '../service/api'
import backE from '../service/backEnd';
import moment from 'moment'


const intialState = {
    segundacarteira: { nometitular: '', email: '', nomedependente: '', cpf: '', tipodoc: '' },
    list: [],
    dados: [],
    dependentes: [],
    nomedep: [],
    abertas: [],
    loading: true
}
const options = [
    { label: 'Guia do Associado', value: 'Guia do Associado' },
    { label: 'Declaração de Quitação', value: 'Declaração de Quitação' }
];

export default class viaCarteirinha extends Component {

    state = { ...intialState }

    componentWillMount() {
        api.get(`/associados/${inscricao}`)
            .then(resp => {
                this.setState({ dados: resp.data[0], loading: false })
            }).catch(err => {
                showMessageError("Error", "Erro ao enviar requisição!")
            })
        api.get(`/dependentes/${inscricao}`)
            .then(resp => {
                this.setState({ dependentes: resp.data })
            }).catch(err => {
                showMessageError("Error", "Erro ao enviar requisição!")
            })

        api.get(`/mensalidades/abertas/${inscricao}`)
            .then(result => {
                this.setState({ abertas: result.data })
            })
    }

    save() {
        const getdate = moment().toDate()
        const horam = moment(getdate).format('DD/MM/YYYY')
        const comparar = this.state.abertas
        var valor;
        comparar.forEach(values => {
            if (values.Vencimento < horam) {
                valor = 1
            } if (values.Vencimento > horam) {
                valor = 0
            }
        })
        if (valor === 1) {
            showMessageError("Alteração Contratual Indisponível no momento!", `
            Alteração Contratual Indisponível no momento
            Verifique se todas as parcelas do plano estão
            devidamente em dia no campo PARCELAS,
            para mais informações ligar para central de Atendimento
            Grupo Zelo segunda sábado 07:00 as 20:30h
            (31)3314-8000.
            `)
            return
        }
        if (valor === 0) {
            const user = {
                "user": this.state.dados.AssEmail,
                "doc": this.state.segundacarteira.tipodoc,
                "name": this.state.dados.Nome,
                "depen": this.state.nomedep.Nome,
                "inscricao": inscricao
            }
            backE.post('/email', user) //api local envio de email
                .then(resp => {
                    showMessageSuccess("Sucesso", "Email de solicitação enviado com sucesso!")
                }).catch(err => {
                    showMessageError("Error", "Erro ao enviar a solicitação!")
                })
        }
    }

    getUpdatedList(segundacarteira, add = true) {
        const list = this.state.list.filter(s => s.id !== segundacarteira.id)
        if (add) list.unshift(segundacarteira)
        return list
    }

    updatedField(event) {
        const segundacarteira = { ...this.state.segundacarteira }
        segundacarteira[event.target.name] = event.target.value
        this.setState({ segundacarteira })
    }

    updatedFieldDoc(event) {
        const segundacarteira = { ...this.state.segundacarteira }
        segundacarteira[event.target.name] = event.target.value.value
        this.setState({ segundacarteira })
    }

    // renderTable() {
    //     return (
    //         <DataTable autoLayout={true} style={{ textAlign: 'center' }} globalFilter={this.state.globalFilter}
    //             value={this.state.list} paginator={true} rows={20} sortField="data" sortOrder={1}>
    //             <Column field="nomedependente" header="Nome Dependente" sortable={false} />
    //             <Column field="tipodoc" header="Documento" sortable={true} />
    //         </DataTable>
    //     )
    // }

    alertVia() {
        if (this.state.segundacarteira.tipodoc != "") {
            { showMessageAlert("Alerta!", "Sera Cobrado uma taxa de R$ 00,00 para emissão do documento.") }
        }
    }

    renderForm() {
        return (
            <Fragment>
                <div><h6>Formulario de solicitação da segunda via de documentos</h6></div>
                <div className="form">
                    <div className="container"></div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-grupo">
                                <label><b>Nome</b></label>
                                <InputText type="text" readOnly className="form-control" name="nometitular" value={this.state.dados.Nome} onChange={e => this.updatedField(e)} />

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="form-grupo">
                                <label><b>E-Mail</b></label>
                                <input type="text" className="form-control" name="email" readOnly value={this.state.dados.AssEmail} onChange={e => this.updatedField(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-grupo">
                                <label><b>Cpf</b></label>
                                <InputMask mask="999.999.999-99" type="text" readonly className="form-control" name="cpf" value={this.state.dados.CPF} placeholder="999.999.999-99" onChange={(e) => this.updatedField(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="form-grupo">
                                <label><b>Nome Dependente</b></label>
                                <DataTable autoLayout={true} style={{ width: 'auto', heigh: 'auto' }} globalFilter={this.state.globalFilter}
                                    selection={this.state.nomedep} onSelectionChange={e => this.setState({ nomedep: e.value })}
                                    value={this.state.dependentes} rows={20} sortField="data" sortOrder={1}>
                                    <Column selectionMode="single" />
                                    <Column field="Nome" />
                                </DataTable>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="form-grupo">
                                <label><b>Selecione o documento para a segunda via:</b></label>
                                {this.alertVia()}
                                <ListBox value={this.state.segundacarteira} style={{ heigh: 'auto' }} name="tipodoc" options={options}
                                    onChange={(e) => this.updatedFieldDoc(e)}
                                    optionLabel="label" optionValue="value" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <button className="btn btn-primary" onClick={e => this.save(e)}>Enviar</button>
                        {/* <button className="btn btn-secundary ml-2" onClick={this.clear}>Cancelar</button> */}
                    </div>
                </div>
            </Fragment>
        )
    }

    render() {
        return (
            <Main>
                <div className='container'><h5><b>Solicitações</b></h5></div>
                <hr />
                {this.state.loading ? <ProgressSpinner style={{ width: '50px', height: '50px', marginLeft: '50%' }}
                /> : this.renderForm()}
            </Main>
        )
    }
}